import actions from "./actions";
import { message } from "antd";
// import { object } from 'prop-types';
import axios from "axios";

const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const { getMenuItemsData } = actions;

export const menuItemsListData = (tableId) => {
  return async (dispatch) => {
    const getData = await DataService.get(`${API.tableMenuData}/${tableId}`);
    if (!getData.data.error) {
      return dispatch(getMenuItemsData(getData?.data?.data));
    } else {
      message.error(getData.data.message);
      return false;
    }
  };
};

export const getHotelDetails = (tableId) => {
  return async (dispatch) => {
    const getData = await DataService.get(`${API.hotelDetails}/${tableId}`);
    if (!getData.data.error) {
      return getData.data;
    } else {
      message.error(getData.data.message);
      return false;
    }
  };
};
export const addOrUpdateReseration = (orderData) => {
  return async (dispatch) => {
    const getData = await DataService.post(`${API.createOrder}`, orderData);
    if (!getData.data.error) {
      return getData.data;
    } else {
      message.error(getData.data.message);
      return false;
    }
  };
};
export const addBookingInChannel = (payload) => {
  return async (dispatch) => {
    try {
      let token = payload?.ota_token;
      // let localProviderCode = "JaTkrvpsR0f0T8MUnK70";
      let providerCode = "ggdyFUXCfUzJj0DNjPDE";
      delete payload?.ota_token;
      let response = await axios.post(
        "https://www.eglobe-solutions.com/webapichannelmanager/extpms/bookings/notif",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ProviderCode: providerCode,
          },
        }
      );

      return response;
    } catch (err) {
      console.log("errerrerrerrerrerrerrerrerr", err);
      return false;
    }
  };
};
export const razorpayCreateOrder = (orderData) => {
  return async (dispatch) => {
    const getData = await DataService.post(
      `${API.razorpayCreateOrder}`,
      orderData
    );
    return getData;
  };
};
export const razorpayPaymnetVerify = (orderData) => {
  return async (dispatch) => {
    const getData = await DataService.post(`${API.paymentverify}`, orderData);
    return getData;
  };
};
export const getAllRateDateWise = (registerId, obj) => {
  return async (dispatch) => {
    try {
      let url = API.getAllRates;
      if (registerId && obj) {
        url = `${API.getAllRates}?registerId=${registerId}&startDate=${obj.startDate}&endDate=${obj.endDate}`;
      }
      const getroomTypeList = await DataService.get(url);
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export const checkRoomAvailblity = (payload) => {
  return async (dispatch) => {
    try {
      payload["timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const getroomTypeList = await DataService.post(API.checkRoom, payload);
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};
