import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import "../../css/custom.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
  Card,
} from "antd";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CaretRightOutlined,
  SearchOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
  FilterFilled,
  CloseOutlined,
  FilterTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { menuItemsListData } from "../../redux/HomePage/actionCreater";
import dish1 from "../../Images/Home/dish1.png";
import menuIcon from "../../Images/Home/menuBook.svg";
import dish4 from "../../Images/Home/dish4.jpg";
import ProductDetail from "./ProductDetail";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
import ViewCart from "./ViewCart";
import TextArea from "antd/es/input/TextArea";
const { Search } = Input;

function Customer({ setCustomerData, customerData }) {
  let localCartInfo = {};
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [currentRegisterData, setCurrentRegisterData] = useState();
  const params = useParams();
  useEffect(() => {
    tableData();
  }, []);
  const [roomType, setRoomtype] = useState([]);
  const [rsSymbol, setRsSymbol] = useState("₹");
  const [shopDetails, setShopDetails] = useState();
  const tableData = async () => {
    setLoad(false);
  };

  return (
    <div style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Card className={styles.cartItemsCard}>
        <h2>Enter your details</h2>
        <Row gutter="10">
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Input
              placeholder="Enter your name"
              onChange={(e) => {
                setCustomerData({
                  ...customerData,
                  name: e.target.value,
                });
              }}
            />
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Input
              placeholder="Enter your phone"
              onChange={(e) => {
                setCustomerData({
                  ...customerData,
                  mobile: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <br />
        <Row gutter="10">
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Input
              placeholder="Enter your email"
              type="email"
              onChange={(e) => {
                setCustomerData({
                  ...customerData,
                  email: e.target.value,
                });
              }}
            />
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Input
              placeholder="Enter your addres"
              onChange={(e) => {
                setCustomerData({
                  ...customerData,
                  shipping_address: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <br />
        <Checkbox>
          I acknowledge and accept the terms and conditions, cancellation
          policies & hotel policies.
        </Checkbox>
      </Card>
      <Card className={styles.cartItemsCard}>
        <h2>Special Request</h2>
        <p>
          Special requests cannot be guaranteed – but the property will do its
          best to meet your needs. You can always make a special request after
          your booking is complete!
        </p>
        <TextArea
          placeholder="Your message"
          onChange={(e) => {
            setCustomerData({
              ...customerData,
              additional: e.target.value,
            });
          }}
        />
      </Card>
    </div>
  );
}

export default Customer;
