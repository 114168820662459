import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import "../../css/custom.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
  Radio,
  InputNumber,
  DatePicker,
  Select,
  Card,
} from "antd";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import ViewCart from "./ViewCart";

import Customer from "./Customer";
import {
  getAllRateDateWise,
  checkRoomAvailblity,
} from "../../redux/HomePage/actionCreater";
import { useDispatch } from "react-redux";
const { TextArea } = Input;
function ProductDetail({
  roomType,
  rsSymbol,
  currentRegisterData,
  shopDetails,
}) {
  const dispatch = useDispatch();
  const [reservationStartDate, setReservationStartDate] = useState(
    moment().format("ddd DD MMM YYYY")
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    moment().add(1, "day").format("ddd DD MMM YYYY")
  );
  const [numberOfNights, setNumberOfNights] = useState(
    moment(reservationEndDate).diff(moment(reservationStartDate), "days")
  );
  const [stayForm] = Form.useForm();
  const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});
  let [selectedRoomtype, setSelectedRoomType] = useState([]);
  const [showBill, setShowBill] = useState(false);
  const [overBookingModal, setOverBookingModal] = useState(false);
  function disabledEndDate(current) {
    return current && current <= moment(reservationStartDate);
  }
  let [customerData, setCustomerData] = useState({
    name: "",
    mobile: "",
    shipping_address: "",
    email: "",
    additional: "",
  });
  useEffect(() => {
    let array = [];
    roomType.map((val) => {
      console.log("vallldasdadadadada", val);
      array.push({
        room_type: {
          _id: val._id,
          name: val.name,
          default_adult: val.default_adult,
          default_children: val.default_children,
          default_infant: val.default_infant,
          tax_group: val.tax_group,
          rate_plan_list: val.rate_plan,
          numberOfRooms: [...Array(val.numberOfRooms)],
          firstTimeSelect: true,
        },
        rate_plan: {},
        numberOfRooms: 0,
        rooms: [],
        total_guest: 1,
        calculatedPriceWithoutTax: 0,
        calCulatedTaxPrice: 0,
        calculatedPriceWithTax: 0,
        roomTypeId: val._id,
        isManualPricing: false,
        original_room_type: val,
      });
    });
    setSelectedRoomType(array);
  }, []);

  const onSelectRatePlan = (ratePlan, index) => {
    let default_adult_rate = 0;
    let extra_adult_rate = 0;
    let extra_child_rate = 0;
    let extra_infant_rate = 0;
    const currentDate = new Date(reservationStartDate);
    const endDate = new Date(reservationEndDate);

    while (currentDate < endDate) {
      let thatDay = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      if (
        rateDateWiseObj &&
        ratePlan &&
        ratePlan.rate_id &&
        rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
      ) {
        let latestObj =
          rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
        default_adult_rate += Number(latestObj.default_adult_rate);
        extra_adult_rate += Number(latestObj.extra_adult_rate);
        extra_child_rate += Number(latestObj.extra_child_rate);
        extra_infant_rate += Number(latestObj.extra_infant_rate);
      } else {
        default_adult_rate += Number(ratePlan.default_adult_rate);
        extra_adult_rate += Number(ratePlan.extra_adult_rate);
        extra_child_rate += Number(ratePlan.extra_child_rate);
        extra_infant_rate += Number(ratePlan.extra_infant_rate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    ratePlan = {
      ...ratePlan,
      default_adult_rate: (default_adult_rate / numberOfNights).toString(),
      extra_adult_rate: (extra_adult_rate / numberOfNights).toString(),
      extra_child_rate: (extra_child_rate / numberOfNights).toString(),
      extra_infant_rate: (extra_infant_rate / numberOfNights).toString(),
    };
    selectedRoomtype[index]["rate_plan"] = ratePlan;
    setSelectedRoomType([...selectedRoomtype]);
  };
  console.log("roomType", selectedRoomtype);
  const fetchDateWiseRate1 = async () => {
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      return obj;
    }
  };
  const [reservationDisable, setReservationDisable] = useState(false);
  const checkRoomAvailble = async (
    reservationStartDate,
    reservationEndDate,
    room,
    roomTypeData,
    noRoom
  ) => {
    setReservationDisable(true);
    let startDate = moment(reservationStartDate).format("YYYY-MM-DD");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    let sellQty =
      noRoom != undefined ? noRoom : roomTypeData?.numberOfRooms?.length;
    if (currentRegisterData && currentRegisterData._id) {
      let dateObj = await fetchDateWiseRate1();
      let startDate1 = moment(reservationStartDate);
      let endDate1 = moment(reservationEndDate);
      while (moment(startDate1).isBefore(endDate1)) {
        let rate_id = roomTypeData?.rate_plan
          ? roomTypeData?.rate_plan[0]?.rate_id
          : roomTypeData?.rate_plan_list[0]?.rate_id;
        if (dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`]) {
          let thisObj =
            dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`];
          if (thisObj?.value?.sell && thisObj?.value?.sell != "") {
            if (Number(thisObj?.value?.sell) < sellQty) {
              sellQty = Number(thisObj?.value?.sell);
            }
          }
        }
        startDate1 = moment(startDate1).add(1, "days");
      }
    }
    let response = await dispatch(
      checkRoomAvailblity({
        reservationStartDate: startDate,
        reservationEndDate: endDate,
        roomTypeId: roomTypeData?._id,
        needRoom: room,
        totalRoom: sellQty,
      })
    );
    if (response && response.data) {
      if (response.data.avilableStatus == false) {
        setOverBookingModal(true);
        setReservationDisable(true);
        return true;
      } else {
        setReservationDisable(false);
        return false;
      }
    }
  };
  function createArrayOfObjects(dataCount) {
    const arrayOfObjects = [];
    for (let i = 1; i <= dataCount; i++) {
      const object = {
        adults: 1,
        children: 0,
        infants: 0,
      };
      arrayOfObjects.push(object);
    }
    return arrayOfObjects;
  }
  function removeArrayOfObjects(originalArray, x) {
    const newArray = originalArray.slice(0, x);
    return newArray;
  }

  const handleArray = (x, array) => {
    if (array.length < x) {
      let checkPush = x - array.length;
      let newArray = createArrayOfObjects(checkPush);
      array = [...array, ...newArray];
      return array;
    } else if (array.length > x) {
      return removeArrayOfObjects(array, x);
    }
  };
  const onSelectRoomNo = (no, index, array) => {
    selectedRoomtype[index]["rooms"] = handleArray(no, array);
    selectedRoomtype[index]["numberOfRooms"] = no;
    checkRoomAvailble(
      reservationStartDate,
      reservationEndDate,
      no,
      selectedRoomtype[index]?.room_type
    );
    setSelectedRoomType([...selectedRoomtype]);
  };
  const updateGuest = (roomTypeIndex, roomIndex, guestType, checkPlusMinus) => {
    if (checkPlusMinus == "plus") {
      selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] =
        ++selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
      setSelectedRoomType([...selectedRoomtype]);
      return selectedRoomtype;
    } else {
      if (selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] > 0) {
        selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] =
          --selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      } else {
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      }
    }
  };
  return (
    <>
      <Row className={styles.cardContainerRow}>
        <Col span={18}>
          <>
            <Form form={stayForm} name="stayDetails">
              <Row className="stayBox">
                <Col
                  md={12}
                  sm={12}
                  lg={12}
                  xxl={12}
                  xs={24}
                  className="gutter-row"
                >
                  <Form.Item
                    name="stay_start_date"
                    label="Stay Start Date"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <DatePicker
                      format="ddd DD MMM YYYY"
                      style={{ width: "100%" }}
                      defaultValue={moment(
                        reservationStartDate,
                        "ddd DD MMM YYYY"
                      )}
                      size="large"
                      onChange={(date, string) => {
                        let nights = moment(reservationEndDate).diff(
                          moment(string),
                          "days"
                        );
                        setReservationStartDate(string);
                        let outDate = reservationEndDate;
                        if (nights <= 0) {
                          outDate = moment(string)
                            .add(1, "days")
                            .format("ddd DD MMM YYYY");
                          stayForm.setFieldsValue({
                            stay_end_date: moment(outDate, "ddd DD MMM YYYY"),
                          });
                          setReservationEndDate(outDate);
                          setNumberOfNights(
                            moment(outDate).diff(moment(string), "days")
                          );
                        } else {
                          setNumberOfNights(
                            moment(reservationEndDate).diff(
                              moment(string),
                              "days"
                            )
                          );
                        }
                        if (selectedRoomtype[0]?.numberOfRooms) {
                          checkRoomAvailble(
                            string,
                            outDate,
                            selectedRoomtype[0]?.numberOfRooms,
                            selectedRoomtype[0]?.room_type
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                  <Form.Item
                    name="stay_end_date"
                    label="Stay End Date"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <DatePicker
                      format="ddd DD MMM YYYY"
                      style={{ width: "100%" }}
                      defaultValue={moment(
                        reservationEndDate,
                        "ddd DD MMM YYYY"
                      )}
                      size="large"
                      disabledDate={disabledEndDate}
                      onChange={(date, string) => {
                        setNumberOfNights(
                          moment(string).diff(
                            moment(reservationStartDate),
                            "days"
                          )
                        );
                        setReservationEndDate(string);
                        if (selectedRoomtype[0]?.numberOfRooms) {
                          checkRoomAvailble(
                            reservationStartDate,
                            string,
                            selectedRoomtype[0]?.numberOfRooms,
                            selectedRoomtype[0]?.room_type
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {showBill ? (
              <Customer
                setCustomerData={setCustomerData}
                customerData={customerData}
              />
            ) : (
              <Col
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={styles.cardColumns}
              >
                {selectedRoomtype?.map((val, index) => {
                  let singleRoomType = val.original_room_type;
                  if (singleRoomType) {
                    return (
                      <div className={styles.productCardBox}>
                        <div className={styles.MenuItemContainer}>
                          <div>
                            <Row gutter="10">
                              <Col
                                xxl={6}
                                xl={9}
                                lg={11}
                                md={12}
                                sm={18}
                                xs={24}
                              >
                                <Swiper
                                  navigation={true}
                                  modules={[Navigation]}
                                  className="mySwiper"
                                >
                                  {singleRoomType?.room_images?.map(
                                    (oneImage) => {
                                      return (
                                        <SwiperSlide>
                                          <img
                                            src={oneImage?.url}
                                            alt=""
                                            height={200}
                                            width={400}
                                          />
                                        </SwiperSlide>
                                      );
                                    }
                                  )}
                                </Swiper>
                              </Col>
                              <Col
                                xxl={6}
                                xl={9}
                                lg={11}
                                md={12}
                                sm={18}
                                xs={24}
                              >
                                <h1> {singleRoomType?.name}</h1>
                                <ul>
                                  {singleRoomType?.room_ammenties?.map(
                                    (item, index) => {
                                      return <li>{item}</li>;
                                    }
                                  )}
                                </ul>
                                <p>{singleRoomType?.summary_of_roomtype}</p>
                              </Col>
                              <Col
                                xxl={6}
                                xl={9}
                                lg={11}
                                md={12}
                                sm={18}
                                xs={24}
                              >
                                <h3>Select Your Meal Plan</h3>
                                <Radio.Group
                                  style={{ marginBottom: "10px" }}
                                  buttonStyle="solid"
                                  className="tick-radio"
                                >
                                  {singleRoomType.rate_plan.map((item) => {
                                    return (
                                      <Radio.Button
                                        value={item.rate_id}
                                        style={{
                                          marginRight: "10px",
                                          marginBottom: "10px",
                                          background: "white  ",
                                        }}
                                        onClick={() => {
                                          onSelectRatePlan(item, index);
                                        }}
                                      >
                                        {val.rate_plan?.rate_id ==
                                          item?.rate_id && (
                                          <>
                                            <svg
                                              width="13px"
                                              style={{ marginRight: "2px" }}
                                              viewBox="0 0 123 102"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                fill="#BE3D5D"
                                              />
                                            </svg>
                                            &nbsp;
                                          </>
                                        )}

                                        {`${
                                          item.rate_name
                                        } (${rsSymbol}${Number(
                                          item.default_adult_rate
                                        ).toFixed(2)})`}
                                      </Radio.Button>
                                    );
                                  })}
                                </Radio.Group>
                              </Col>
                              <Col
                                xxl={6}
                                xl={9}
                                lg={11}
                                md={12}
                                sm={18}
                                xs={24}
                              >
                                <div className={styles.additemBox}>
                                  <div
                                    className={styles.addBtn}
                                    style={{ textAlign: "center" }}
                                  >
                                    <Select
                                      // allowClear={true}

                                      style={{ marginBottom: 15, width: "70%" }}
                                      className="high_addpd room_reservation"
                                      // showSearch
                                      // filterOption={(input, option) =>
                                      //   option.value
                                      //     .toString()
                                      //     .toLowerCase()
                                      //     .indexOf(
                                      //       input.toString().toLowerCase()
                                      //     ) >= 0
                                      // }
                                      placeholder="Select No. of Rooms"
                                      dropdownRender={(menu) => (
                                        <div>{menu}</div>
                                      )}
                                      onChange={(j) =>
                                        onSelectRoomNo(j, index, val.rooms)
                                      }
                                    >
                                      <Select.Option key={0} value={0}>
                                        0
                                      </Select.Option>
                                      {val.room_type.numberOfRooms?.map(
                                        (data, i) => (
                                          <Select.Option
                                            key={i + 1}
                                            value={i + 1}
                                          >
                                            {i + 1}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                    <br></br>
                                    {val.rooms.length ? (
                                      <p>
                                        <small>
                                          This room type can occupy
                                          {val.room_type.default_adult} x
                                          adults,{" "}
                                          {val.room_type.default_children} x
                                          children,{" "}
                                          {val.room_type.default_infant} x
                                          infants{" "}
                                        </small>
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    {val.rooms.length > 0
                                      ? val.rooms?.map((r, i) => {
                                          return (
                                            <>
                                              <div className="  ">
                                                {
                                                  <p
                                                    style={{
                                                      marginLeft: "10px",
                                                      marginBottom: "7px",
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Room {i + 1}{" "}
                                                    </strong>
                                                  </p>
                                                }{" "}
                                                <div className="guest_numbersBox">
                                                  <div className="guestCounter">
                                                    <p
                                                      style={{
                                                        marginLeft: "10px",
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      Adults
                                                    </p>
                                                    <div className="quantityies qucuentlft">
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "adults",
                                                            "minus"
                                                          )
                                                        }
                                                      >
                                                        −
                                                      </span>
                                                      {r.adults}
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "adults",
                                                            "plus"
                                                          )
                                                        }
                                                      >
                                                        +
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="guestCounter">
                                                    <p
                                                      style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      children
                                                    </p>
                                                    <div className="quantityies qucuentlft">
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "children",
                                                            "minus"
                                                          )
                                                        }
                                                      >
                                                        −
                                                      </span>
                                                      {r.children}
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "children",
                                                            "plus"
                                                          )
                                                        }
                                                      >
                                                        +
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="guestCounter">
                                                    <p
                                                      style={{
                                                        marginLeft: "5px",
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      Infants
                                                    </p>
                                                    <div className="quantityies qucuentlft">
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "infants",
                                                            "minus"
                                                          )
                                                        }
                                                      >
                                                        −
                                                      </span>
                                                      {r.infants}
                                                      <span
                                                        className="qunatity-adjust"
                                                        onClick={() =>
                                                          updateGuest(
                                                            index,
                                                            i,
                                                            "infants",
                                                            "plus"
                                                          )
                                                        }
                                                      >
                                                        +
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })
                                      : ""}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </Col>
            )}
          </>
        </Col>
        {overBookingModal && (
          <Modal
            title="Not Available"
            visible={overBookingModal}
            onCancel={() => setOverBookingModal(false)}
            onOk={() => setOverBookingModal(false)}
          >
            <p>
              Selected rooms are currently occupied for this category. Please
              wait for availability or consider offering alternative category.
            </p>
          </Modal>
        )}
        <Col span={6}>
          <ViewCart
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
            selectedRoomtype={selectedRoomtype.filter(
              (val) => val.numberOfRooms > 0
            )}
            numberOfNights={numberOfNights}
            rsSymbol={rsSymbol}
            setShowBill={setShowBill}
            showBill={showBill}
            customerData={customerData}
            currentRegisterData={currentRegisterData}
            reservationDisable={reservationDisable}
            setSelectedRoomType={setSelectedRoomType}
            shopDetails={shopDetails}
          />
        </Col>
      </Row>
    </>
  );
}

export default ProductDetail;
