import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../css/custom.css";
import styles from "./footer.module.css";

import { UpOutlined } from "@ant-design/icons";
import { Modal, Skeleton, Space } from "antd";
import moment from "moment";
import { NavLink } from "react-router-dom";

function Footer(props) {
    const { hotelData, color } = props;
    const [load, setLoad] = useState(false);
    const params = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const disabledDate = (current) => {
        return current && current < moment().startOf("day");
    };
    if (load) {
        return (
            <div className={styles.SkeletonContainer}>
                <Skeleton title width={150} active={true} />
                <br />
                <br />
                <div className={styles.cardSkeleton}>
                    <Skeleton paragraph={{ rows: 3 }} active={true} />
                    <Skeleton.Image active={true} />
                </div>
                <br />
                <div className={styles.cardSkeleton}>
                    <Skeleton paragraph={{ rows: 3 }} active={true} />
                    <Skeleton.Image active={true} />
                </div>
                <br />
                <div className={styles.cardSkeleton}>
                    <Skeleton paragraph={{ rows: 3 }} active={true} />
                    <Skeleton.Image active={true} />
                </div>
                <Space>
                    <Skeleton.Button active={true} size="large" />
                    <Skeleton.Input active={true} />
                </Space>
            </div>
        );
    }

    return (
        <>
            <footer className={styles.footerSection} style={{ "--text-color": color }}>
                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-4 ">
                            <ul className={`${styles.ulFooter}`}>
                                <li>
                                    <i
                                        class="fa-solid fa-location-dot"
                                        style={{
                                            marginLeft: "-15px",
                                            marginTop: "5px",
                                        }}
                                    ></i>
                                    &nbsp;
                                    <b className="text-white"> Address:</b>
                                </li>
                                <li>{hotelData?.hotel_address}</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 ">
                            <ul className={` ${styles.ulFooter}`}>
                                <li>
                                    <i
                                        class="fa-solid fa-phone"
                                        style={{
                                            marginLeft: "-15px",
                                            marginTop: "5px",
                                        }}
                                    ></i>
                                    &nbsp;
                                    <b className="text-white"> Phone:</b>
                                </li>
                                <li>
                                    <a href="#">{hotelData?.hotel_mobile}</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 ">
                            <ul className={` ${styles.ulFooter}`}>
                                <li>
                                    <i
                                        class="fa-regular fa-envelope"
                                        style={{
                                            marginLeft: "-15px",
                                            marginTop: "5px",
                                        }}
                                    ></i>
                                    &nbsp;
                                    <b className="text-white"> Email:</b>
                                </li>
                                <li>
                                    <a href="mailto:sales@posease.com">
                                        {hotelData?.hotel_email}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr className={styles.hrLine} />
                    <div className="d-flex justify-content-between">
                        <p className={styles.pFooter}>
                            All right reserved by PosEase copyright © 2024
                        </p>
                        <div className="d-flex gap-3">
                            <p className={styles.followbtn}>Follow us</p>
                            <a href={hotelData?.facebook_link} target="_blank">
                                <i
                                    className={`fa-brands fa-facebook ${styles.socialIcon}`}
                                    style={{ fontSize: "30px", filter: "brightness(10)" }}
                                ></i>
                            </a>
                            <a href={hotelData?.instagram_link} target="_blank">
                                <i
                                    className={`fa-brands fa-instagram ${styles.socialIcon}`}
                                    style={{ fontSize: "30px", filter: "brightness(10)" }}
                                ></i>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <div className={styles.pageFooter}>
        {1 ? (
          <div className={styles.viewCartDetailFooter}>
            <div className={styles.cartItems} onClick={showModal}>
              <p>₹2120.00</p>
              <UpOutlined className={styles.cartIcon} />
            </div>
            <div className={styles.cartDetailBtn}>
              <NavLink to={{ pathname: `/app/${params.table_id}/viewCart` }}>
                <button>Pay Now</button>
              </NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        className={`modalPayNow ${styles.modalPayNow}`}
        title={<b>Price Summary</b>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={styles.modalPayable}>
          <div className="d-flex justify-content-between ">
            <div className="d-flex flex-column">
              <h3>Room Total</h3>
            </div>
            <div className="d-flex flex-column">
              <h3>₹2000.00</h3>
            </div>
          </div>
          <div className="d-flex justify-content-between ">
            <div className="d-flex flex-column">
              <h3>GST</h3>
            </div>
            <div className="d-flex flex-column">
              <h3>₹120.00</h3>
            </div>
          </div>
        </div>
        <div className={`d-flex justify-content-between ${styles.totalPay}`}>
          <h3>Total payable</h3>
          <h3>₹2120.00</h3>
        </div>
      </Modal> */}
        </>
    );
}

export default Footer;
