import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
  Radio,
  InputNumber,
  DatePicker,
  Select,
  Card,
  message,
} from "antd";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

import moment from "moment";
import "../../css/custom.css";
import styles from "./BookingDetails.module.css";
import ViewCart from "./ViewCart";
import {
  getAllRateDateWise,
  checkRoomAvailblity,
} from "../../redux/HomePage/actionCreater";
import { useDispatch } from "react-redux";
import Customer from "./customer";

function BookingDetails({
  roomType,
  rsSymbol,
  currentRegisterData,
  shopDetails,
  reservationStartDate,
  reservationEndDate,
  rateDateWiseObj,
  cancelPolicy,
  setReservationData,
  reservationData,
  setShowBill1,
  showBill1,
  paymentType,
  currency,
  custom_payment,
  color,
  price_adjustment,
  percentage_adjustment
}) {
  const [isFeatured, setIsFeatured] = useState(false);
  const [rooms, setRooms] = useState([{ adults: 2, children: 1, infants: 0 }]); // Initial room
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modalAmenities, setModalAmenities] = useState([]);
  const [modalRoomDescription, setModalRoomDescription] = useState()
  const swiperRef = useRef(null);

  const [numberOfNights, setNumberOfNights] = useState(
    moment(reservationEndDate).diff(moment(reservationStartDate), "days")
  );
  const [stayForm] = Form.useForm();
  // const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});
  let [selectedRoomtype, setSelectedRoomType] = useState([]);
  const [showBill, setShowBill] = useState(false);
  const [overBookingModal, setOverBookingModal] = useState(false);
  const [load, setLoad] = useState(true);
  const fetchDateWiseRate1 = async () => {
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      return obj;
    }
  };
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const openModal = (amenities, roomIndex, imageIndex, title, decsription) => {
    setSelectedRoomIndex(roomIndex);
    setModalAmenities(amenities);
    setSelectedImageIndex(imageIndex);
    setModalTitle(title);
    setModalRoomDescription(decsription)
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handlePrevInModal = (currentImageIndex, roomIndex, imageCount) => {
    const prevIndex = (currentImageIndex - 1 + imageCount) % imageCount;
    setSelectedImageIndex(prevIndex);
  };

  const handleNextInModal = (currentImageIndex, roomIndex, imageCount) => {
    const nextIndex = (currentImageIndex + 1) % imageCount;
    setSelectedImageIndex(nextIndex);
  };
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  let [customerData, setCustomerData] = useState({
    name: "",
    mobile: "",
    shipping_address: "",
    email: "",
    additional: "",
  });
  const createPriceAdjustment = (calculatedRate) => {
    if (price_adjustment == "decrease" && percentage_adjustment != "") {
      calculatedRate = calculatedRate - (calculatedRate * percentage_adjustment / 100)
      return calculatedRate
    } else if (price_adjustment == "increase" && percentage_adjustment != "") {
      calculatedRate = calculatedRate + (calculatedRate * percentage_adjustment / 100)
      return calculatedRate
    } else {
      return calculatedRate
    }

  }
  const onSelectRatePlan = (ratePlan, rateDateWiseObj) => {
    let default_adult_rate = 0;
    let extra_adult_rate = 0;
    let extra_child_rate = 0;
    let extra_infant_rate = 0;
    const currentDate = new Date(reservationStartDate);
    const endDate = new Date(reservationEndDate);


    ratePlan?.adult_rates?.map((j, i) => {
      let myRate = 0;
      if (i) {
        const currentDate1 = new Date(reservationStartDate);
        const endDate1 = new Date(reservationEndDate);
        while (currentDate1 < endDate1) {
          let thatDay = currentDate1.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });

          if (
            rateDateWiseObj &&
            ratePlan &&
            ratePlan.rate_id &&
            rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
          ) {
            let latestObj =
              rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
            if (latestObj[`default_adult${j.numberOfAdult}`]) {
              myRate += Number(latestObj[`default_adult${j.numberOfAdult}`]);
            } else {
              myRate += Number(j.rate);
            }
          } else {
            myRate += Number(j.rate);
          }

          currentDate1.setDate(currentDate1.getDate() + 1);
        }
        let calculatedRate = createPriceAdjustment(myRate / numberOfNights)

        j.rate = (calculatedRate).toString();
      }
      return j;
    });
    while (currentDate < endDate) {
      let thatDay = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      if (
        rateDateWiseObj &&
        ratePlan &&
        ratePlan.rate_id &&
        rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
      ) {
        let latestObj =
          rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
        default_adult_rate += Number(
          latestObj.default_adult_rate != undefined
            ? latestObj.default_adult_rate
            : ratePlan?.default_adult_rate
        );
        extra_adult_rate += Number(
          latestObj.extra_adult_rate != undefined
            ? latestObj.extra_adult_rate
            : ratePlan.extra_adult_rate
        );
        extra_child_rate += Number(
          latestObj.extra_child_rate != undefined
            ? latestObj.extra_child_rate
            : ratePlan?.extra_child_rate
        );
        extra_infant_rate += Number(
          latestObj.extra_infant_rate != undefined
            ? latestObj.extra_infant_rate
            : ratePlan?.extra_infant_rate
        );
      } else {
        default_adult_rate += Number(ratePlan.default_adult_rate);
        extra_adult_rate += Number(ratePlan.extra_adult_rate);
        extra_child_rate += Number(ratePlan.extra_child_rate);
        extra_infant_rate += Number(ratePlan.extra_infant_rate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    let calCulatedDefaultRate = createPriceAdjustment(default_adult_rate / numberOfNights)
    let calCulatedExtraAdultRate = createPriceAdjustment(extra_adult_rate / numberOfNights)
    let calCulatedExtraChildRate = createPriceAdjustment(extra_child_rate / numberOfNights)
    let calCulatedExtraInfantRate = createPriceAdjustment(extra_infant_rate / numberOfNights)

    ratePlan = {
      ...ratePlan,
      default_adult_rate: (calCulatedDefaultRate).toString(),
      extra_adult_rate: (calCulatedExtraAdultRate).toString(),
      extra_child_rate: (calCulatedExtraChildRate).toString(),
      extra_infant_rate: (calCulatedExtraInfantRate).toString(),
    };
    return ratePlan;
  };
  useEffect(() => {
    setLoad(true);
    let array = [];
    setNumberOfNights(
      moment(reservationEndDate).diff(moment(reservationStartDate), "days")
    );

    roomType.map((val) => {
      val.rate_plan.map((rate) => {
        if (
          rate.show_booking_engine ||
          rate?.show_booking_engine == undefined
        ) {
          console.log("asjdjasdasjdaasdasdadadadadadadad", rate)
          let myObj = {
            room_type: {
              _id: val._id,
              name: val.name,
              default_adult: val.default_adult,
              default_children: val.default_children,
              default_infant: val.default_infant,
              tax_group: val.tax_group,
              rate_plan_list: val.rate_plan,
              numberOfRooms: [...Array(val.numberOfRooms)],
              firstTimeSelect: true,
            },
            rate_plan: onSelectRatePlan(rate, rateDateWiseObj),
            numberOfRooms: 0,
            rooms: [],
            total_guest: 1,
            calculatedPriceWithoutTax: 0,
            calCulatedTaxPrice: 0,
            calculatedPriceWithTax: 0,
            roomTypeId: val._id,
            isManualPricing: false,
            original_room_type: val,
            is_more: false,
            image_index: 0,
          };
          if (
            currentRegisterData?.connect_channel &&
            currentRegisterData?.channel_key != "" &&
            roomType?.channel_room_code != ""
          ) {
            myObj["room_type"]["channel_room_code"] = val?.channel_room_code;
            myObj["room_type"]["channel_room_name"] = val?.channel_room_name;
          }
          array.push(myObj);
        }
      });
    });
    setSelectedRoomType(array);
    setLoad(false);
  }, [rateDateWiseObj, roomType]);
  const toggleFeatured = (is_more, index) => {
    let newroomType = selectedRoomtype;
    newroomType[index]["is_more"] = !is_more;
    setSelectedRoomType([...newroomType]);
  };
  const handleNext = (image_index, mainIndex, totalImage) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
    // if (totalImage == image_index + 1) {
    //   selectedRoomtype[mainIndex]["image_index"] = 0;
    //   setSelectedRoomType([...selectedRoomtype]);
    // } else {
    //   selectedRoomtype[mainIndex]["image_index"] = image_index + 1;
    //   setSelectedRoomType([...selectedRoomtype]);
    // }
  };
  const handlePrev = (image_index, mainIndex, totalImage) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
    // if (image_index > 0) {
    //   selectedRoomtype[mainIndex]["image_index"] = image_index - 1;
    //   setSelectedRoomType([...selectedRoomtype]);
    // } else {
    //   selectedRoomtype[mainIndex]["image_index"] = totalImage - 1;
    //   setSelectedRoomType([...selectedRoomtype]);
    // }
  };
  const addRoom = () => {
    setRooms([...rooms, { adults: 2, children: 1, infants: 0 }]);
  };

  // Function to handle removing the last room
  const removeRoom = () => {
    if (rooms.length > 1) {
      setRooms(rooms.slice(0, -1)); // Remove the last room
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [showBill]);

  const updateRoom = (index, key, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][key] = value;
    setRooms(updatedRooms);
  };

  const [reservationDisable, setReservationDisable] = useState(false);
  const checkRoomAvailble = async (
    reservationStartDate,
    reservationEndDate,
    room,
    roomTypeData,
    noRoom
  ) => {
    setReservationDisable(true);
    let startDate = moment(reservationStartDate).format("YYYY-MM-DD");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    let sellQty =
      noRoom != undefined ? noRoom : roomTypeData?.numberOfRooms?.length;
    if (currentRegisterData && currentRegisterData._id) {
      let dateObj = await fetchDateWiseRate1();
      let startDate1 = moment(reservationStartDate);
      let endDate1 = moment(reservationEndDate);
      while (moment(startDate1).isBefore(endDate1)) {
        let rate_id = roomTypeData?.rate_plan
          ? roomTypeData?.rate_plan[0]?.rate_id
          : roomTypeData?.rate_plan_list[0]?.rate_id;
        if (dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`]) {
          let thisObj =
            dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`];
          if (thisObj?.value?.sell && thisObj?.value?.sell != "") {
            if (Number(thisObj?.value?.sell) < sellQty) {
              sellQty = Number(thisObj?.value?.sell);
            }
          }
        }
        startDate1 = moment(startDate1).add(1, "days");
      }
    }
    let response = await dispatch(
      checkRoomAvailblity({
        reservationStartDate: startDate,
        reservationEndDate: endDate,
        roomTypeId: roomTypeData?._id,
        needRoom: room,
        totalRoom: sellQty,
      })
    );
    if (response && response.data) {
      if (response.data.avilableStatus == false) {
        setOverBookingModal(true);
        setReservationDisable(true);
        return true;
      } else {
        setReservationDisable(false);
        return false;
      }
    }
  };
  function createArrayOfObjects(dataCount, roomObject) {
    const arrayOfObjects = [];
    for (let i = 1; i <= dataCount; i++) {
      const object = {
        adults: 1,
        children: 0,
        infants: 0,
      };
      arrayOfObjects.push(object);
    }
    return arrayOfObjects;
  }
  function removeArrayOfObjects(originalArray, x) {
    const newArray = originalArray.slice(0, x);
    return newArray;
  }

  const handleArray = (x, array, roomObject) => {
    if (array.length < x) {
      let checkPush = x - array.length;
      let newArray = createArrayOfObjects(checkPush, roomObject);
      array = [...array, ...newArray];
      return array;
    } else if (array.length > x) {
      return removeArrayOfObjects(array, x);
    }
  };
  const onSelectRoomNo = (no, index, array, roomObject, type) => {
    if (reservationDisable && type == "add") {
      message.error(
        "Please remove the first unavailable room after adding a new room."
      );
      return true
    }
    selectedRoomtype[index]["rooms"] = handleArray(no, array, roomObject);
    selectedRoomtype[index]["numberOfRooms"] = no;
    checkRoomAvailble(
      reservationStartDate,
      reservationEndDate,
      no,
      selectedRoomtype[index]?.room_type
    );
    setSelectedRoomType([...selectedRoomtype]);
  };
  const updateGuest = (roomTypeIndex, roomIndex, guestType, checkPlusMinus) => {
    if (checkPlusMinus == "plus") {
      let updateNumber =
        1 + selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
      if (
        guestType == "adults" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.original_room_type?.max_adult
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "children" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.original_room_type?.max_infant
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "infants" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.original_room_type?.max_children
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] =
        ++selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];

      setSelectedRoomType([...selectedRoomtype]);
      return selectedRoomtype;
    } else {
      if (selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] > 0) {
        selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] =
          --selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      } else {
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      }
    }
  };

  if (load) {
    return (
      <div className={styles.SkeletonContainer}>
        <Skeleton title width={150} active={true} />
        <br />
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <Space>
          <Skeleton.Button active={true} size="large" />
          <Skeleton.Input active={true} />
        </Space>
      </div>
    );
  }

  return (
    <div>
      <section className={styles.detailSection}>
        <div className="container">
          <div className="row">
            <div
              className={`col-12 col-md-12 col-lg-8 d-flex flex-column gap-3 ${styles.cardScroller}`}
            >
              {showBill ? (
                <div>
                  {windowWidth > 1024 && (
                    <Customer
                      setCustomerData={setCustomerData}
                      customerData={customerData}
                      cancelPolicy={cancelPolicy}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {selectedRoomtype?.map((val, index) => {
                    let singleRoomType = val.original_room_type;
                    if (singleRoomType) {
                      return (
                        <div>
                          {" "}
                          <div className={styles.cardDetails}>
                            <div className="row">
                              <div className="col-12 col-md-5">
                                <div className="carousel slide">
                                  <div
                                    id="carouselExample"
                                    className="carousel slide"
                                    data-ride="carousel"
                                  >
                                    <div className="carousel-inner d-flex align-items-center">
                                      <div
                                        className={`d-flex justify-content-between py-4 ${styles.setBtnDiv}`}
                                      >
                                        {/* <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handlePrev(
                                              val.image_index,
                                              index,
                                              singleRoomType?.room_images
                                                ?.length
                                            )
                                          }
                                        >
                                          <i
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            class="fa-solid fa-chevron-left"
                                          ></i>
                                        </a> */}

                                        {/* <a
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleNext(
                                              val.image_index,
                                              index,
                                              singleRoomType?.room_images
                                                ?.length
                                            )
                                          }
                                        >
                                          <i
                                            style={{
                                              color: "white",
                                              fontSize: "12px",
                                            }}
                                            class="fa-solid fa-chevron-right"
                                          ></i>
                                        </a> */}
                                      </div>
                                      <Swiper
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        loop={true}
                                        pagination={{ clickable: true }}
                                        slidesPerView={1}
                                        className="myDetailsSwiper"
                                        ref={swiperRef}
                                      >
                                        {singleRoomType?.room_images?.map(
                                          (item, i) => {
                                            return (
                                              <SwiperSlide key={i}>
                                                <img
                                                  className={`d-block ${styles.itemCarouselImg} `}
                                                  src={item.url}
                                                  alt=""
                                                  onClick={() =>
                                                    openModal(
                                                      singleRoomType?.room_ammenties,
                                                      index,
                                                      i,
                                                      `${singleRoomType?.name} ${val?.rate_plan?.rate_name}`,
                                                      singleRoomType?.summary_of_roomtype
                                                    )
                                                  }
                                                />
                                              </SwiperSlide>
                                            );
                                          }
                                        )}
                                      </Swiper>
                                      {/* {singleRoomType?.room_images?.map(
                                        (item, i) => (
                                          <div
                                            className={`carousel-item ${
                                              styles.itemCarouselImg
                                            } ${
                                              i === val.image_index
                                                ? "active"
                                                : ""
                                              }`}
                                            key={item?._id}
                                          >
                                            <img
                                              src={item.url}
                                              className={`d-block `}
                                              onClick={() =>
                                                openModal(
                                                  singleRoomType?.room_ammenties,
                                                  index,
                                                  i,
                                                  `${singleRoomType?.name} ${val?.rate_plan?.rate_name}`
                                                )
                                              }
                                              alt={"Image"}
                                            />
                                          </div>
                                        )
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-7">
                                <div className={styles.cardAllDetails}>
                                  <div className="d-flex justify-content-between">
                                    <label>
                                      {singleRoomType?.name}{" "}
                                      {val?.rate_plan?.rate_name}
                                    </label>
                                    <div className="d-flex flex-column">
                                      <h2>
                                        {rsSymbol}
                                        {Number(
                                          val.rate_plan?.default_adult_rate
                                        )}
                                        <span>/ Night</span>
                                      </h2>
                                      <small style={{ textAlign: "center" }}>
                                        Excluding Taxes
                                      </small>
                                    </div>
                                  </div>
                                  <hr />
                                  <ul>
                                    {val.is_more
                                      ? singleRoomType?.room_ammenties?.map(
                                        (k) => {
                                          return <li>{k}</li>;
                                        }
                                      )
                                      : singleRoomType?.room_ammenties
                                        ?.slice(0, 6)
                                        .map((k) => {
                                          return <li>{k}</li>;
                                        })}
                                  </ul>
                                  {val.is_more && (
                                    <p>{singleRoomType?.summary_of_roomtype}</p>
                                  )}
                                  <a
                                    onClick={() =>
                                      toggleFeatured(val.is_more, index)
                                    }
                                  >
                                    {val.is_more
                                      ? "See Less..."
                                      : " See more..."}
                                  </a>{" "}
                                  <div>
                                    {val.numberOfRooms ? (
                                      <div>
                                        <div className={styles.roomsAdd}>
                                          <a
                                            onClick={() => {
                                              onSelectRoomNo(
                                                val.numberOfRooms - 1,
                                                index,
                                                val.rooms,
                                                {
                                                  adults:
                                                    val?.room_type
                                                      ?.default_adult,
                                                  children:
                                                    val?.room_type
                                                      ?.default_children,
                                                  infants:
                                                    val?.room_type
                                                      ?.default_infant,
                                                }, "minus"
                                              );
                                            }}
                                          >
                                            <i class="fa-solid fa-minus"></i>
                                          </a>
                                          <div>
                                            {val.rooms.length == 1
                                              ? val.rooms.length
                                              : val.rooms.length}
                                          </div>
                                          <a
                                            onClick={() => {
                                              onSelectRoomNo(
                                                val.numberOfRooms + 1,
                                                index,
                                                val.rooms,
                                                {
                                                  adults:
                                                    val?.room_type
                                                      ?.default_adult,
                                                  children:
                                                    val?.room_type
                                                      ?.default_children,
                                                  infants:
                                                    val?.room_type
                                                      ?.default_infant,
                                                }, "add"
                                              );
                                            }}
                                          >
                                            <i class="fa-solid fa-plus"></i>
                                          </a>
                                        </div>
                                        {val.rooms.map((room, i) => (
                                          <>
                                            <div
                                              key={i}
                                              className={`d-flex py-2 gap-3 align-items-center ${styles.respCounter}`}
                                            >
                                              <h6>{`Room ${i + 1}:`}</h6>
                                              <div className=" d-flex">
                                                <div className=" d-flex">
                                                  <p
                                                    className={
                                                      styles.titleSubCounter
                                                    }
                                                  >
                                                    Adult:
                                                  </p>
                                                  &nbsp;
                                                  <div
                                                    className={
                                                      styles.subCounter
                                                    }
                                                  >
                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "adults",
                                                          "minus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-minus"></i>
                                                    </a>
                                                    <div>
                                                      {room.adults
                                                        ? room.adults
                                                        : 0}
                                                    </div>
                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "adults",
                                                          "plus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-plus"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className=" d-flex">
                                                  <p
                                                    className={
                                                      styles.titleSubCounter
                                                    }
                                                  >
                                                    Children:
                                                  </p>
                                                  &nbsp;
                                                  <div
                                                    className={
                                                      styles.subCounter
                                                    }
                                                  >
                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "children",
                                                          "minus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-minus"></i>
                                                    </a>
                                                    <div>
                                                      {room.children
                                                        ? room.children
                                                        : 0}
                                                    </div>

                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "children",
                                                          "plus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-plus"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className=" d-flex">
                                                  <p
                                                    className={
                                                      styles.titleSubCounter
                                                    }
                                                  >
                                                    Infants:
                                                  </p>
                                                  &nbsp;
                                                  <div
                                                    className={
                                                      styles.subCounter
                                                    }
                                                  >
                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "infants",
                                                          "minus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-minus"></i>
                                                    </a>
                                                    <div>
                                                      {room.infants
                                                        ? room.infants
                                                        : 0}
                                                    </div>

                                                    <a
                                                      onClick={() =>
                                                        updateGuest(
                                                          index,
                                                          i,
                                                          "infants",
                                                          "plus"
                                                        )
                                                      }
                                                    >
                                                      <i class="fa-solid fa-plus"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                      </div>
                                    ) : (
                                      <div
                                        className={styles.addRoomBtn}
                                        style={{ "--text-color": color }}
                                        onClick={() =>
                                          onSelectRoomNo(1, index, val.rooms, {
                                            adults:
                                              val?.room_type?.default_adult,
                                            children:
                                              val?.room_type?.default_children,
                                            infants:
                                              val?.room_type?.default_infant,
                                          }, "add")
                                        }
                                      >
                                        Add Room
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />{" "}
                          <Modal
                            visible={visible && selectedRoomIndex === index}
                            footer={null}
                            onCancel={closeModal}
                            centered
                            width={1200}
                            title={<b>{modalTitle}</b>}
                          >
                            {selectedRoomIndex !== null && (
                              <div className="carousel slide">
                                <div className="carousel-inner d-flex align-items-center">
                                  <div
                                    className={`d-flex justify-content-between py-4 ${styles.setBtnDiv}`}
                                  >
                                    {/* <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handlePrevInModal(
                                          selectedImageIndex,
                                          selectedRoomIndex,
                                          singleRoomType?.room_images?.length
                                        )
                                      }
                                    >
                                      <i
                                        style={{
                                          color: "white",
                                          fontSize: "16px",
                                        }}
                                        className="fa-solid fa-chevron-left"
                                      ></i>
                                    </a>

                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleNextInModal(
                                          selectedImageIndex,
                                          selectedRoomIndex,
                                          singleRoomType?.room_images?.length
                                        )
                                      }
                                    >
                                      <i
                                        style={{
                                          color: "white",
                                          fontSize: "16px",
                                        }}
                                        className="fa-solid fa-chevron-right"
                                      ></i>
                                    </a> */}
                                  </div>
                                  <Swiper
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    slidesPerView={1}
                                    className="myDetailsSwiper"
                                    ref={swiperRef}
                                  >
                                    {singleRoomType?.room_images?.map(
                                      (item, i) => {
                                        return (
                                          <SwiperSlide key={i}>
                                            <img
                                              className={`d-block  ${styles.modalCarousel}`}
                                              src={
                                                selectedRoomtype[
                                                  selectedRoomIndex
                                                ]?.original_room_type
                                                  ?.room_images[
                                                  selectedImageIndex
                                                ]?.url
                                              }
                                              alt=""
                                              onClick={() =>
                                                openModal(
                                                  singleRoomType?.room_ammenties,
                                                  index,
                                                  i,
                                                  `${singleRoomType?.name} ${val?.rate_plan?.rate_name}`,
                                                  singleRoomType?.summary_of_roomtype
                                                )
                                              }
                                            />
                                          </SwiperSlide>
                                        );
                                      }
                                    )}
                                  </Swiper>
                                  <div
                                    className={`carousel-item active ${styles.desCarousel}`}
                                  >
                                    <img
                                      src={
                                        selectedRoomtype[selectedRoomIndex]
                                          ?.original_room_type?.room_images[
                                          selectedImageIndex
                                        ]?.url
                                      }
                                      className={`d-block  ${styles.modalCarousel}`}
                                      alt="Selected Room Image"
                                    />
                                  </div>
                                </div>

                                {/* <ol
                                  className={`carousel-indicators ${styles.customIndicators}`}
                                >
                                  {selectedRoomtype[
                                    selectedRoomIndex
                                  ]?.original_room_type?.room_images?.map(
                                    (item, i) => (
                                      <li
                                        key={item?._id}
                                        className={
                                          i === selectedImageIndex
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => setSelectedImageIndex(i)}
                                        data-target="#carouselExample"
                                      ></li>
                                    )
                                  )}
                                </ol> */}
                              </div>
                            )}
                            <div className={styles.modalDescription}>
                              <label>
                                <i class="fa-solid fa-hotel"></i> &nbsp;Hotel
                                Services & Amenities
                              </label>

                              <ul className={styles.modalAmenities}>
                                {modalAmenities?.map((amenity, i) => (
                                  <li key={i}>{amenity}</li>
                                ))}
                              </ul>
                              {modalRoomDescription && <p>{modalRoomDescription}</p>}
                            </div>
                          </Modal>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
            {overBookingModal && (
              <Modal
                title="Not Available"
                visible={overBookingModal}
                onCancel={() => setOverBookingModal(false)}
                onOk={() => setOverBookingModal(false)}
                okButtonProps={{
                  style: {
                    backgroundColor: "#2c2a25", // Change this to any color you prefer
                    borderColor: "#2c2a25", // Match the border color to the background
                    color: "#ffffff", // Change the text color
                  },
                }}
              >
                <p>
                  Selected rooms are currently occupied for this category.
                  Please wait for availability or consider offering alternative
                  category.
                </p>
              </Modal>
            )}

            <div className="col-12 col-md-12 col-lg-4">
              <ViewCart
                reservationStartDate={reservationStartDate}
                reservationEndDate={reservationEndDate}
                selectedRoomtype={selectedRoomtype.filter(
                  (val) => val.numberOfRooms > 0
                )}
                numberOfNights={numberOfNights}
                rsSymbol={rsSymbol}
                setShowBill={setShowBill}
                showBill={showBill}
                customerData={customerData}
                currentRegisterData={currentRegisterData}
                reservationDisable={reservationDisable}
                setSelectedRoomType={setSelectedRoomType}
                shopDetails={shopDetails}
                setReservationData={setReservationData}
                setShowBill1={setShowBill1}
                showBill1={showBill1}
                paymentType={paymentType}
                currency={currency}
                custom_payment={custom_payment}
                color={color}
              />
            </div>
            {showBill && windowWidth <= 1024 && (
              <div>
                <br></br>
                <Customer
                  setCustomerData={setCustomerData}
                  customerData={customerData}
                  cancelPolicy={cancelPolicy}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default BookingDetails;
