import React, { useState, useEffect, useRef } from "react";
import styles from "./HotelDetails.module.css";
import "../../css/custom.css";
import {
  Input,
  Button,
  Select,
  DatePicker,
  Form,
  Space,
  Skeleton,
  Modal,
  Row,
  Col,
} from "antd";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CaretRightOutlined,
  SearchOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
  FilterFilled,
  CloseOutlined,
  FilterTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import menuIcon from "../../Images/Home/menuBook.svg";
import mainIcon from "../../Images/favicon.png";
import moment from "moment";
import welcomeIcon from "../../Images/logo 3.svg";
import { getHotelDetails } from "../../redux/HomePage/actionCreater";
const { Search } = Input;

function HotelDetails({ state }) {
  const [form] = Form.useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [currentRegisterData, setCurrentRegisterData] = useState();
  const [index, setIndex] = useState(0);
  const params = useParams();
  useEffect(() => {
    tableData();
  }, []);
  const [roomType, setRoomtype] = useState([]);
  const [rsSymbol, setRsSymbol] = useState("₹");
  const [shopDetails, setShopDetails] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [hotelFeatures, setHotelFeatures] = useState([]);
  const [color, setColor] = useState("#2d2a21");
  const [stayForm] = Form.useForm();
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleDescription1 = () => {
    setIsExpanded1(!isExpanded1);
  };
  const toggleFeatured = () => {
    setIsFeatured(!isFeatured);
  };
  const [hotelData, setHotelData] = useState();
  const tableData = async () => {
    setLoad(true);
    let response = await dispatch(getHotelDetails(params.table_id));
    if (response.data) {
      setLoad(false);
      let chunkSize = 6; // Define the chunk size
      let Newaaray = [];

      // Split the array into chunks dynamically
      for (
        let i = 0;
        i < response?.data?.hotel_features.length;
        i += chunkSize
      ) {
        Newaaray.push({
          id: Newaaray.length + 1,
          data: response?.data?.hotel_features.slice(i, i + chunkSize), // Create chunks
        });
      }
      setHotelFeatures(Newaaray);
      setColor(response.data?.bg_color);
      setHotelData(response.data);
    }
  };
  const [reservationStartDate, setReservationStartDate] = useState(
    moment().format("ddd DD MMM YYYY")
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    moment().add(1, "day").format("ddd DD MMM YYYY")
  );
  const handleDateChange = (dates) => {
    setReservationStartDate(moment(dates[0]).format("ddd DD MMM YYYY"));
    setReservationEndDate(moment(dates[1]).format("ddd DD MMM YYYY"));
  };
  function disabledEndDate(current) {
    return current && current <= moment(reservationStartDate);
  }
  console.log("hotelFeatures", hotelFeatures);
  if (load) {
    return (
      <div className={styles.SkeletonContainer}>
        <Skeleton title width={150} active={true} />
        <br />
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <Space>
          <Skeleton.Button active={true} size="large" />
          <Skeleton.Input active={true} />
        </Space>
      </div>
    );
  }

  const handleNext = () => {
    if (index < hotelData?.hotel_section?.length - 1) {
      setIndex((prev) => prev + 1);
    } else {
      setIndex(0);
    }
  };
  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 1);
    } else {
      setIndex(hotelData?.hotel_section?.length - 1);
    }
  };

  const navigateBookPage = () => {
    navigate(
      `/hotel/booking/${params.table_id}?startDate=${reservationStartDate}&&endDate=${reservationEndDate}`
    );
  };

  return (
    <>
      <div
        style={{ "--text-color": color }}
        className={styles.mainDiv}
        data-stellar-background-ratio="0.5"
      >
        <header className={styles.mainHeader}>
          <div className="container">
            <div className=" d-flex justify-content-between">
              <div>
                <h1 className={styles.mainLogo}>{hotelData?.hotel_name}</h1>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-4">
                <Button
                  className={styles.headerBookBtn}
                  shape="round"
                  size="large"
                  onClick={() => navigateBookPage()}
                >
                  Book Now
                </Button>
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="container d-flex justify-content-center text-center">
            <div className={styles.bannerSection}>
              <h3>Book Now for the</h3>
              <h1>Fast, Easy and Secure Hotel Booking</h1>
            </div>
            <a className={styles.mouse} href="#next">
              <div className={styles.mouseIcon}>
                <span className={styles.mouseWheel}></span>
              </div>
            </a>
          </div>
        </section>
        <section className={styles.cardSection}>
          <div className="container d-flex justify-content-center position-relative">
            <div className={styles.cardDiv}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: "100%" }}
                >
                  <div className="">
                    <h5>Check In & Check Out</h5>
                    <Space
                      direction="vertical"
                      className={styles.spaceDatePicker}
                    >
                      <Form form={stayForm} name="stayDetails">
                        <Row className={styles.rowWithGap}>
                          <Col
                            md={12}
                            sm={12}
                            lg={12}
                            xxl={12}
                            xs={24}
                            className="gutter-row"
                          >
                            <Form.Item
                              name="stay_start_date"
                              className={styles.datePickerForm}
                            >
                              <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={moment(
                                  reservationStartDate,
                                  "ddd DD MMM YYYY"
                                )}
                                size="large"
                                disabledDate={disabledDate}
                                onChange={(date, string) => {
                                  let nights = moment(reservationEndDate).diff(
                                    moment(string),
                                    "days"
                                  );
                                  setReservationStartDate(string);
                                  let outDate = reservationEndDate;
                                  if (nights <= 0) {
                                    outDate = moment(string)
                                      .add(1, "days")
                                      .format("ddd DD MMM YYYY");
                                    stayForm.setFieldsValue({
                                      stay_end_date: moment(
                                        outDate,
                                        "ddd DD MMM YYYY"
                                      ),
                                    });
                                    setReservationEndDate(outDate);
                                  }
                                }}
                                format="ddd DD MMM YYYY"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                            <Form.Item
                              name="stay_end_date"
                              className={styles.datePickerForm}
                            >
                              <DatePicker
                                style={{ width: "100%" }}
                                defaultValue={moment(
                                  reservationEndDate,
                                  "ddd DD MMM YYYY"
                                )}
                                size="large"
                                disabledDate={disabledEndDate}
                                onChange={(date, string) => {
                                  setReservationEndDate(string);
                                }}
                                format="ddd DD MMM YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Space>
                  </div>

                  <div className={` ${styles.bookBtnDiv} text-center`}>
                    <Button
                      className={styles.bookBtn}
                      shape="round"
                      size="large"
                      onClick={() => navigateBookPage()}
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        style={{ "--text-color": color }}
        className={styles.aboutSection}
      >
        <div className="container position-relative">
          <div className="row d-flex flex-column justify-content-start align-items-center">
            <div className="col-12 col-xl-8 d-flex flex-column justify-content-start align-items-center">
              <h2>{hotelData?.hotel_title}</h2>
              <hr />
              <p>
                {hotelData?.hotel_description?.slice(0, 500)}
                {isExpanded && (
                  <>
                    {hotelData?.hotel_description?.slice(
                      500,
                      hotelData?.hotel_description?.length
                    )}
                  </>
                )}
                <a
                  onClick={toggleDescription}
                  className={styles.toggleButton}
                  style={{
                    cursor: "pointer",
                    color: "black",
                    textDecoration: "underline",
                  }}
                >
                  {isExpanded ? "See Less" : " See More"}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ "--text-color": color }}
        className={styles.sliderSection}
      >
        <div className="container">
          <div className="carousel slide">
            <div className="carousel-inner">
              <div className="row">
                <div
                  className={`col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center px-5  ${styles.setResTitleDes}`}
                >
                  {hotelData?.hotel_section.map((item, i) => (
                    <>
                      <div
                        className={`carousel-item ${i === index ? "active" : ""
                          }`}
                        key={item?.id}
                      >
                        <div className=" d-none d-md-block px-5">
                          <h2 className="text-white">{item.section_title}</h2>
                          <p className="text-white">
                            {item.section_description}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                  <div
                    className={`d-flex justify-content-between py-4 ${styles.setBtnDiv}`}
                  >
                    <a style={{ cursor: "pointer" }} onClick={handlePrev}>
                      <i
                        style={{
                          color: "white",
                          fontSize: "16px",
                        }}
                        class="fa-solid fa-chevron-left"
                      ></i>
                    </a>

                    <a style={{ cursor: "pointer" }} onClick={handleNext}>
                      <i
                        style={{
                          color: "white",
                          fontSize: "16px",
                        }}
                        class="fa-solid fa-chevron-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-between align-items-center">
                  {hotelData?.hotel_section.map((item, i) => (
                    <div
                      className={`carousel-item ${styles.itemCarouselImg} ${i === index ? "active" : ""
                        }`}
                      key={item?.section_title}
                    >
                      <img
                        src={item.section_image}
                        className={`d-block ${styles.imgCarousel}`}
                        alt={item.section_title}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.featureSection}>
        <div className="container position-relative">
          <h3>FEATURED AMENITIES ON-SITE</h3>
          <div className="row d-flex align-items-center">
            {isFeatured
              ? hotelFeatures.map((val, index) => {
                return (
                  <div className="col-md-4">
                    <ul>
                      {val?.data?.map((val) => {
                        return (
                          <li>
                            <i class="fa-solid fa-check"></i>&nbsp; {val}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })
              : hotelFeatures.slice(0, 3).map((val, index) => {
                return (
                  <div className="col-md-4">
                    <ul>
                      {val?.data?.map((val) => {
                        return (
                          <li>
                            <i class="fa-solid fa-check"></i>&nbsp; {val}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>

          <a
            onClick={toggleFeatured}
            className={styles.toggleButton}
            style={{
              cursor: "pointer",
              color: "black",
              textDecoration: "underline",
            }}
          >
            {isFeatured ? "See Less" : " See More"}
          </a>
        </div>
      </section>
      <footer
        style={{ "--text-color": color }}
        className={styles.footerSection}
      >
        <div className="container">
          <div className="row ">
            <div className="col-12 col-md-4 ">
              <ul className={`${styles.ulFooter}`}>
                <li>
                  <i
                    class="fa-solid fa-location-dot"
                    style={{
                      marginLeft: "-15px",
                      marginTop: "5px",
                    }}
                  ></i>
                  &nbsp;
                  <b className="text-white"> Address:</b>
                </li>
                <li>{hotelData?.hotel_address}</li>
              </ul>
            </div>
            <div className="col-12 col-md-4 ">
              <ul className={` ${styles.ulFooter}`}>
                <li>
                  <i
                    class="fa-solid fa-phone"
                    style={{
                      marginLeft: "-15px",
                      marginTop: "5px",
                    }}
                  ></i>
                  &nbsp;
                  <b className="text-white"> Phone:</b>
                </li>
                <li>
                  <a href="tel:+919501795038">{hotelData?.hotel_mobile}</a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 ">
              <ul className={` ${styles.ulFooter}`}>
                <li>
                  <i
                    class="fa-regular fa-envelope"
                    style={{
                      marginLeft: "-15px",
                      marginTop: "5px",
                    }}
                  ></i>
                  &nbsp;
                  <b className="text-white"> Email:</b>
                </li>
                <li>
                  <a href="mailto:sales@posease.com">
                    {hotelData?.hotel_email}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr className={styles.hrLine} />
          <div className="d-flex justify-content-between">
            <p className={styles.pFooter}>
              All right reserved by PosEase copyright © 2024
            </p>
            <div className="d-flex gap-3">
              <p className={styles.followbtn}>Follow us</p>
              <a href={hotelData?.facebook_link} target="_blank">
                <i
                  className={`fa-brands fa-facebook ${styles.socialIcon}`}
                  style={{ fontSize: "30px", filter: "brightness(10)" }}
                ></i>
              </a>
              <a href={hotelData?.instagram_link} target="_blank">
                <i
                  className={`fa-brands fa-instagram ${styles.socialIcon}`}
                  style={{ fontSize: "30px", filter: "brightness(10)" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default HotelDetails;
