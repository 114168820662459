import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Card,
  Radio,
  Modal,
} from "antd";
import {
  RightOutlined,
  MoreOutlined,
  HeartOutlined,
  StarFilled,
  HeartFilled,
  BankOutlined,
  GiftOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  PlusCircleOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import {
  addOrUpdateReseration,
  razorpayCreateOrder,
  razorpayPaymnetVerify,
} from "../../redux/HomePage/actionCreater";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getItem, setItem } from "../../utility/localStorageControl";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
const { TextArea } = Input;

function ViewCart(props) {
  let {
    selectedRoomtype,
    numberOfNights,
    rsSymbol,
    setShowBill,
    showBill,
    customerData,
    currentRegisterData,
    reservationStartDate,
    reservationEndDate,
    reservationDisable,
    setSelectedRoomType,
    shopDetails,
  } = props;
  let customerDetails = customerData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();

  const [totalCalculatedTax, setTotalCalculatedtax] = useState(0);
  const [totalCalculatedPrice, setTotalCalculatedPrice] = useState(0);
  const [totalCalculatedPriceWithoutTax, setTotalCalculatedPriceWihoutTax] =
    useState(0);
  const calCulatedTaxPriceofThisRoomType = (
    totalPrice,
    taxes,
    checkIsInclusive
  ) => {
    let totalTax = 0;
    let inclusiveTax = 0;
    let totalTaxPercent = 0;
    taxes?.map((val) => {
      if (checkIsInclusive != "remove") {
        totalTaxPercent += Number(val.tax_percentage);
      }
    });
    if (checkIsInclusive == true) {
      totalTax = calculateTaxInclusive(totalPrice, totalTaxPercent).taxAmount;
      inclusiveTax = totalTax;
    } else if (checkIsInclusive == false) {
      totalTax = (totalPrice * totalTaxPercent) / 100;
    }
    inclusiveTax = parseFloat(inclusiveTax.toFixed(2));
    totalTax = parseFloat(totalTax.toFixed(2));
    return { totalTax, inclusiveTax };
  };
  function calculateTaxInclusive(totalAmount, taxRate) {
    taxRate = taxRate / 100;
    var originalAmount = totalAmount / (1 + taxRate);
    var taxAmount = totalAmount - originalAmount;
    return {
      originalAmount: originalAmount,
      taxAmount: taxAmount,
    };
  }
  const calculatedPriceOfThisRoomType = (selectRoomType) => {
    if (selectRoomType.room_type.name != "") {
      let totalPrice = 0;
      let totalTaxPrice = 0;
      let totalGuest = 0;

      selectRoomType.rooms?.map((val) => {
        // adult calculated
        let oneRoomCalCulatedPrice = 0;
        if (selectRoomType.room_type.default_adult >= val.adults) {
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice +
              Number(selectRoomType.rate_plan.default_adult_rate));
        } else {
          let checkExtraAdult =
            val.adults - selectRoomType.room_type.default_adult;
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice +
              Number(selectRoomType.rate_plan.default_adult_rate));
          let extraPriceOfAdult =
            numberOfNights *
            (checkExtraAdult *
              Number(selectRoomType.rate_plan.extra_adult_rate));
          oneRoomCalCulatedPrice = oneRoomCalCulatedPrice + extraPriceOfAdult;
        }

        // Extra children calculated
        if (val.children > selectRoomType.room_type.default_children) {
          {
            let checkExtraChildren =
              val.children - selectRoomType.room_type.default_children;

            let extraPriceOfChildren =
              numberOfNights *
              (checkExtraChildren *
                Number(selectRoomType.rate_plan.extra_child_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfChildren;
          }
        }

        // Extra infants calculated
        if (val.infants > selectRoomType.room_type.default_infant) {
          {
            let checkExtraInfants =
              val.infants - selectRoomType.room_type.default_infant;

            let extraPriceOfInfants =
              numberOfNights *
              (checkExtraInfants *
                Number(selectRoomType.rate_plan.extra_infant_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfInfants;
          }
        }

        totalPrice = totalPrice + oneRoomCalCulatedPrice;
        totalGuest = val.adults + val.children + val.infants;
      });

      let { totalTax, inclusiveTax } = calCulatedTaxPriceofThisRoomType(
        totalPrice,
        selectRoomType.room_type.tax_group.taxes,
        selectRoomType.room_type.tax_group.taxes_inclusive_in_product_price
      );
      selectRoomType["calculatedPriceWithoutTax"] = totalPrice - inclusiveTax;

      totalTaxPrice = totalTax;
      selectRoomType["calCulatedTaxPrice"] = totalTaxPrice;
      selectRoomType["calCulatedInclusiveTaxPrice"] = inclusiveTax;
      totalPrice = totalPrice + totalTaxPrice - inclusiveTax;
      selectRoomType["calculatedPriceWithTax"] = totalPrice;
      selectRoomType["total_guest"] = totalGuest;
      return selectRoomType;
    }
  };
  useEffect(() => {
    let finalTotalPrice = 0;
    let finalTaxPrice = 0;
    let finalTotalPriceWithoutPrice = 0;

    selectedRoomtype?.map((roomType) => {
      if (roomType.room_type.name != "" && roomType.numberOfRooms > 0) {
        let oneRoomType = calculatedPriceOfThisRoomType(roomType);
        finalTotalPrice = finalTotalPrice + oneRoomType.calculatedPriceWithTax;
        finalTotalPriceWithoutPrice =
          finalTotalPriceWithoutPrice + oneRoomType?.calculatedPriceWithoutTax;
        finalTaxPrice = finalTaxPrice + oneRoomType.calCulatedTaxPrice;
      }
    });
    setTotalCalculatedPriceWihoutTax(finalTotalPriceWithoutPrice);
    setTotalCalculatedPrice(finalTotalPrice);
    setTotalCalculatedtax(finalTaxPrice);
  }, [selectedRoomtype, numberOfNights]);
  const handleSelectRoomType = (selectedRoomtype) => {
    let array = selectedRoomtype.map((val) => {
      if (val?.room_type?.rate_plan_list?.length) {
        let findIndex = val.room_type.rate_plan_list.findIndex(
          (k) => k.rate_id == val.rate_plan.rate_id
        );
        if (findIndex != -1) {
          val.room_type.rate_plan_list[findIndex] = val.rate_plan;
        }
        return val;
      } else {
        return val;
      }
    });
    return array;
  };
  const createReservationDetails = async () => {
    let formData = {};
    if (currentRegisterData) {
      formData["prefix"] = currentRegisterData.receipt_number_prefix;
      formData["customer"] = customerDetails;
      formData["reservationInDate"] =
        moment(reservationStartDate).format("YYYY-MM-DD");
      formData["reservationOutDate"] =
        moment(reservationEndDate).format("YYYY-MM-DD");
      console.log("reservationStartDate", reservationStartDate);
      let startObject = new Date(reservationStartDate);
      startObject.setHours(12, 30, 0, 0);
      let endObject = new Date(reservationEndDate);
      endObject.setHours(12, 30, 0, 0);
      formData["register_id"] = currentRegisterData?._id;
      formData["register_data"] = {
        _id: currentRegisterData?._id,
        subscription_status: currentRegisterData.subscription_status,
        user_id: currentRegisterData?.user_id,
        main_register_id: currentRegisterData?.register_id,
        shop_name: shopDetails?.shop_name,
        voucher_footer: currentRegisterData?.voucher_footer,
        bill_header: currentRegisterData?.bill_header,
      };
      formData["reservationInDateAndTime"] = startObject;
      formData["reservationOutDateAndTime"] = endObject;
      formData["totalNights"] = numberOfNights;
      formData["roomTypes"] = handleSelectRoomType(selectedRoomtype);
      formData["quickReservation"] = false;
      formData["reservationInTime"] = "12:30 pm";
      formData["reservationOutTime"] = "12:30 pm";
      formData["general"] = {
        source: "OTA",
        channel: "Booking Engine",
      };
      // formData["reservationCreatedStaffUserId"] = 152;
      // formData["reservationUpdateStaffUserId"] = 45;
      formData["reservationBookTime"] = new Date();
      formData["reservationUpdateTime"] = new Date();
      let paymentObj = {
        discount: 0,
        roomCharges: totalCalculatedPriceWithoutTax,
        roomChargesTax: totalCalculatedTax,
        grandTotal: totalCalculatedPrice,
        currency: rsSymbol,
        extraCharges: 0,
        received: 0,
      };

      formData["payment"] = paymentObj;
    }

    return formData;
  };
  const submitReservation = async () => {
    if (customerDetails.name == undefined || customerDetails.name == "") {
      message.error("Enter your name");
      return true;
    }
    // setLoader(true);
    let payload = await createReservationDetails();

    if (payload == true) {
      // setReservationDisable(false);
      // setLoader(false);
      return true;
    }
    console.log("payloadacfasfaasfaasfasfasfafasfafaf", payload);
    const getReservation = await dispatch(addOrUpdateReseration(payload));
    if (getReservation) {
      setShowBill(false);
      setSelectedRoomType([]);
      // window.location.reload();
    }
  };
  const handleVerifyPayment = async (payload) => {
    const verificationResult = await dispatch(
      razorpayPaymnetVerify({
        payment_id: payload?.razorpay_payment_id,
        order_id: payload?.razorpay_order_id,
        signature: payload?.razorpay_signature,
      })
    );
    console.log("verificationResult", verificationResult);
    if (verificationResult.success) {
      alert("Payment successfully verified!");
    } else {
      alert("Payment verification failed.");
    }
  };
  const handlePayment = async (body) => {
    const orderDetails = await dispatch(razorpayCreateOrder(body));
    if (orderDetails.data) {
      const options = {
        key: "rzp_live_1oYjL3ETN9IO5N",
        amount: orderDetails.data.amount,
        currency: "INR",
        name: shopDetails?.shop_name,
        description: "Test Transaction",
        order_id: orderDetails.data.id,
        handler: function (response) {
          if (response && response?.razorpay_payment_id) {
            submitReservation();
          }
        },
        prefill: {
          name: "Your Name",
          email: "email@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#ADD8E6",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };
  return (
    <div className={styles.cartDetailContainer}>
      <Row>
        <Col span={24}>
          <div className={styles.cartSummeryContainer}>
            <Divider>ROOMS ADDED</Divider>
            {/* <Card className={styles.cartItemsCard}> */}
            {selectedRoomtype.map((val, index) => {
              return (
                <Card className={styles.cartItemsCard}>
                  <div className={styles.CartItemCounterBox}>
                    {/* <div className={styles.cartItemDetail}>
                      <p>{val?.room_type?.name}</p>
                      <p>
                        {rsSymbol}
                        {Number(val?.rate_plan?.default_adult_rate).toFixed(2)}
                      </p>
                    </div> */}
                    <div className={styles.totalItemPrice}>
                      <div>{val?.rate_plan?.rate_name}</div>
                      <p>
                        {rsSymbol}
                        {Number(val?.rate_plan?.default_adult_rate).toFixed(2)}
                      </p>
                    </div>
                    <div className={styles.totalItemPrice}>
                      <div>Rooms</div>
                      <p>{val.numberOfRooms}</p>
                    </div>
                    <div className={styles.totalItemPrice}>
                      <div>{val?.room_type?.name}</div>
                      <p>
                        {rsSymbol}
                        {Number(val.calculatedPriceWithoutTax).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </Card>
              );
            })}
            {/* </Card> */}

            <Divider>BILL SUMMERY</Divider>
            <Card className={styles.cartItemsCard}>
              <div className={styles.subTotal}>
                <p>SubTotal</p>
                <p>
                  {rsSymbol}
                  {Number(totalCalculatedPriceWithoutTax).toFixed(2)}
                </p>
              </div>
              <div className={styles.subTotal}>
                <p>Tax</p>
                <p>
                  {rsSymbol}
                  {Number(totalCalculatedTax).toFixed(2)}
                </p>
              </div>
              <Divider />
              <div className={styles.CartBillBox}>
                <p>Grand Total</p>
                <p>
                  {rsSymbol}
                  {Number(totalCalculatedPrice).toFixed(2)}
                </p>
              </div>
            </Card>
          </div>

          <div>
            <div className={styles.personalInfoBtn}>
              <Button
                disabled={reservationDisable}
                onClick={() => {
                  if (!showBill) {
                    setShowBill(true);
                  } else {
                    handlePayment({
                      amount: Number(totalCalculatedPrice).toFixed(2),
                    });
                  }
                }}
              >
                {showBill ? "Pay Now" : "Proceed"}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ViewCart;
