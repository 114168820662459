import { Skeleton, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import "../../css/custom.css";
import styles from "./customer.module.css";
import ViewCart from "./ViewCart";

function Customer({ setCustomerData, customerData, cancelPolicy }) {
  const [isFeatured, setIsFeatured] = useState(false);

  const toggleFeatured = () => {
    setIsFeatured(!isFeatured);
  };

  return (
    <div>
      {/* <div className={styles.mainHeading}>
        <div className="container">
          <h1>Complete Your Booking</h1>
        </div>
      </div> */}
      <div className={styles.cardCustomerDetails}>
        <div className="container ">
          <h3>Guest Information</h3>
          <form>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                onChange={(e) => {
                  setCustomerData({
                    ...customerData,
                    name: e.target.value,
                  });
                }}
              />
            </div>

            <div className="mb-3">
              <input
                type="email"
                onChange={(e) => {
                  setCustomerData({
                    ...customerData,
                    email: e.target.value,
                  });
                }}
                className="form-control"
                id="email"
                placeholder="Email"
              />
            </div>

            <div className="mb-3">
              <input
                type="tel"
                onChange={(e) => {
                  setCustomerData({
                    ...customerData,
                    mobile: e.target.value,
                  });
                }}
                className="form-control"
                id="phone"
                placeholder="Phone Number"
              />
            </div>

            <h4>Special Information</h4>
            <p className="text-muted">
              Special requests cannot be guaranteed - but the property will do
              its best to meet your needs. You can always make a special request
              after your booking is complete!
            </p>
            <div className="mb-3">
              <textarea
                className="form-control"
                id="specialMessage"
                rows="3"
                placeholder="Your message..."
                onChange={(e) => {
                  setCustomerData({
                    ...customerData,
                    additional: e.target.value,
                  });
                }}
              ></textarea>
            </div>

            <h4>Cancellation Policy</h4>
            <div>
              <p className="text-muted">
                {cancelPolicy.slice(0, 500)}
                {isFeatured && (
                  <>{cancelPolicy?.slice(500, cancelPolicy?.length)}</>
                )}
              </p>
            </div>
            <a onClick={toggleFeatured}>
              {isFeatured ? "See Less..." : " See more..."}
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Customer;
