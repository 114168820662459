import { Route, Routes, useNavigate } from "react-router-dom";
import { getItem } from "../utility/localStorageControl";
import { lazy, useEffect } from "react";
import HotelList from "../container/home/HotelList";
import HotelDetails from "../container/home/HotelDetails";
import Booking from "../container/Booking/Header";
import ViewCart from "../container/home/ViewCart";
import NotFound from "../container/NotFound/NotFound";
import OrderCompletePage from "../container/StaticPages/OrderCompletePage";
import HotelClosedPage from "../container/StaticPages/HotelClosedPage";

function Private() {
  return (
    <Routes>
      <Route exact path={`/`} element={<HotelList />} />
      <Route exact path={`/hotel/:table_id`} element={<HotelDetails />} />
      <Route exact path={`/hotel/booking/:table_id`} element={<Booking />} />
      <Route exact path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default Private;
