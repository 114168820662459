import React from "react";
import { Row, Col, Form, Input } from "antd";
import moment from "moment";

function ConformationPrint({
  reservationData,
  currentRegisterData,
  pendingPrice,
  roomList,
  numberOfNights,
  shopDetails,
}) {
  let totalRoom = 0;
  let subTotal = 0;
  roomList.map((val) => {
    let { type } = val;
    if (type != "payment" && !val?.cancel) {
      subTotal += Number(val.calculatedPriceWithoutTax);
      if (type == "room") {
        totalRoom += 1;
      }
    }
  });

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className="ReceiptHeading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="left_receipt_heading"
              style={{
                marginBottom: 10,
              }}
            >
              <h4
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                Confirmation
              </h4>
            </div>
          </div>
        </Col>
      </Row>
      <h4
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          fontSize: "20px",
          fontWeight: 700,
          padding: "0",
          marginBottom: "15px",
        }}
      >
        Hello {reservationData?.customerId?.name?.toUpperCase()},
      </h4>
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
          marginBottom: "15px",
        }}
      >
        Thank you for choosing The {shopDetails?.shop_name} for your upcoming
        stay.
      </p>
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
          marginBottom: "15px",
        }}
      >
        We are writing to confirm that we have received your booking and all
        details are in place for your arrival. Your confirmation number is
        <span
          style={{
            fontSize: "16px",
            fontWeight: 800,
            fontFamily: "Arial, Helvetica, sans-serif",
            marginBottom: "15px",
          }}
        >
          {" "}
          {reservationData?.reservationStringId}
        </span>
        .
      </p>
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
          marginBottom: "15px",
        }}
      >
        If you have any special requests or requirements, please let us know and
        we will do our best to accommodate you. Our team is dedicated to
        providing you with personalized service and ensuring that every aspect
        of your stay is exceptional.
      </p>
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
          marginBottom: "15px",
        }}
      >
        Thank you again for choosing {shopDetails?.shop_name} and we look
        forward to seeing you soon.
      </p>
      <div>
        <h4
          style={{
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "left",
            marginBottom: "15px",
            textAlign: "center",
            marginTop: "0px",
          }}
        >
          Hotel details
        </h4>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "800",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              {shopDetails?.shop_name}
            </p>
            {currentRegisterData?.bill_header.split("\n").map((val) => {
              return (
                <p
                  style={{
                    fontSize: "14px",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    width: "100%",
                    marginTop: "0px",
                    marginBottom: "2px",
                  }}
                >
                  {val}
                </p>
              );
            })}
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Booking ID
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {reservationData?.reservationStringId}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4
          style={{
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "left",
            marginBottom: "15px",
            textAlign: "center",
            marginTop: "18px",
          }}
        >
          Booking details
        </h4>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          marginTop: 10,
          columnGap: "50px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Booked by
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {reservationData?.reservationCreatedStaffUserId?.username}
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Confirmed by
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {shopDetails?.shop_name}
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Booked on
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {moment(reservationData?.reservationBookTime).format(
                "ddd DD MMM YYYY"
              )}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          marginTop: 10,
          columnGap: "50px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Check In
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {moment(reservationData?.reservationInDateAndTime).format(
                "ddd DD MMM YYYY"
              )}
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Check Out
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {" "}
              {moment(reservationData?.reservationOutDateAndTime).format(
                "ddd DD MMM YYYY"
              )}
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Number of nights
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {numberOfNights}
            </p>
          </div>
        </div>
      </div>{" "}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          marginTop: 10,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Number of rooms
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              {totalRoom}
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Status
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              Confirmed
            </p>
          </div>
          {/* <div>
            <p
              style={{
                fontSize: "17px",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "5px",
                marginTop: "0px",
              }}
            >
              Status
            </p>
            <p
              style={{
                marginBottom: "2px",
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                width: "100%",
                marginTop: "0px",
              }}
            >
              Confirmed
            </p>
          </div> */}
        </div>
      </div>
      <div>
        <h4
          style={{
            fontFamily: "Arial, Helvetica, sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "left",
            marginBottom: "15px",
            textAlign: "center",
            marginTop: "18px",
          }}
        >
          Rooms details
        </h4>
      </div>
      <table style={{ width: "100%" }}>
        {roomList?.map((val) => {
          let { type } = val;
          if (type == "room" && !val.cancel) {
            return (
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div
                  style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    fontSize: "15px",
                    padding: "0",
                    textAlign: "left",
                  }}
                >
                  <div>
                    {val?.room_type?.name} {val.room_name}.{" "}
                    {val?.rate_plan?.rate_name}
                  </div>
                  {val.guest.adults > 0 && (
                    <div>Adults X {val.guest.adults} </div>
                  )}
                  {val.guest.children > 0 && (
                    <div>Children X {val.guest.children} </div>
                  )}
                  {val.guest.infants > 0 && (
                    <div>Infants X {val.guest.infants} </div>
                  )}
                  <p style={{ marginBottom: "5px" }}></p>
                </div>
                <div
                  style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    fontSize: "15px",
                    padding: "0",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  {Number(val?.calculatedPriceWithoutTax).toFixed(2)}
                  <p style={{ marginBottom: "5px" }}></p>
                </div>
              </div>
            );
          } else if (type == "addtional-item" && !val.cancel) {
            return (
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div
                  style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    fontSize: "15px",
                    padding: "0",
                    textAlign: "left",
                  }}
                >
                  {val.poseaseBill ? (
                    <div>In Room Service | #{val.item}</div>
                  ) : (
                    <div>
                      {val.item} x {val.amount.qty}
                    </div>
                  )}

                  <p style={{ marginBottom: "5px" }}></p>
                </div>
                <div
                  style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    fontSize: "15px",
                    padding: "0",
                    marginBottom: "5px",
                    textAlign: "right",
                  }}
                >
                  {Number(val?.calculatedPriceWithoutTax).toFixed(2)}
                  <p style={{ marginBottom: "5px" }}></p>
                </div>
              </div>
            );
          }
        })}
      </table>
      <table style={{ width: "100%" }}>
        <tr>
          <hr
            style={{
              margin: "4px 0 0",
              borderTop: "1px dotted black",
            }}
          />
        </tr>
      </table>
      <tr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: "15x",
              fontFamily: "Arial, Helvetica, sans-serif",
              textAlign: "left",
              marginBottom: "1px",
            }}
          >
            Subtotal
          </p>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "1px",
            }}
          >
            {Number(subTotal).toFixed(2)}
          </p>
        </div>
      </tr>
      {reservationData?.payment?.roomChargesTax > 0 && (
        <tr>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Arial, Helvetica, sans-serif",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Taxes
            </p>
            <p
              style={{
                fontSize: "15px",
                textAlign: "left",
                fontFamily: "Arial, Helvetica, sans-serif",
                marginBottom: "1px",
              }}
            >
              {Number(reservationData?.payment?.roomChargesTax).toFixed(2)}
            </p>
          </div>
        </tr>
      )}
      <tr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Arial, Helvetica, sans-serif",
              textAlign: "left",
              marginBottom: "1px",
            }}
          >
            Total
          </p>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "1px",
            }}
          >
            {Number(reservationData?.payment?.grandTotal).toFixed(2)}
          </p>
        </div>
      </tr>
      <table style={{ width: "100%" }}>
        <tr>
          <hr
            style={{
              margin: "4px 0 0",
              borderTop: "2px dotted black",
            }}
          />
        </tr>
      </table>
      <tr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Arial, Helvetica, sans-serif",
              textAlign: "left",
              marginBottom: "0px",
            }}
          >
            Received
          </p>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "0px",
            }}
          >
            {Number(
              Number(reservationData?.payment?.grandTotal) -
                Number(pendingPrice)
            ).toFixed(2)}
          </p>
        </div>
      </tr>
      <tr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Arial, Helvetica, sans-serif",
              textAlign: "left",
              marginBottom: "1px",
            }}
          >
            Outstanding
          </p>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "1px",
            }}
          >
            {Number(pendingPrice).toFixed(2)}
          </p>
        </div>
      </tr>
      {currentRegisterData.voucher_footer &&
        currentRegisterData.voucher_footer != "" && (
          <>
            <br></br>
            <table style={{ width: "100%" }}>
              <tr>
                <hr
                  style={{
                    margin: "1px 0 0",
                    borderTop: "2px dotted black",
                  }}
                />
              </tr>
            </table>

            {currentRegisterData?.voucher_footer.split("\n").map((val) => {
              return (
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    textAlign: "left",
                    marginTop: "1px",
                    marginBottom: "0px",
                  }}
                >
                  {val}
                </p>
              );
            })}
          </>
        )}
    </>
  );
}

export default ConformationPrint;
