/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
// export const BASE_URL = "http://172.105.35.50:4005/api/";

// Authentication related API urls
export const LOGIN = "/users/login";

export const API = {
  // tableMenuData: "tables/resInfo",
  tableMenuData: "room_type/pmsInfo",
  createOrder: "hotel/reservation/bookingEngine/create",
  getAllRates: "rate/list",
  checkRoom: "hotel/roomInfo/check-availability",
  razorpayCreateOrder: "hotel/reservation/razorpay/create",
  paymentverify: "hotel/reservation/razorpay/verify-payment",
  hotelDetails: "booking_setup/get",
};
