import "../../css/custom.css";
import styles from "./ViewCart.module.css";
import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Skeleton,
  Drawer,
  Form,
  Card,
  Radio,
  Modal,
} from "antd";
import {
  RightOutlined,
  MoreOutlined,
  HeartOutlined,
  StarFilled,
  HeartFilled,
  BankOutlined,
  GiftOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  PlusCircleOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import {
  addBookingInChannel,
  addOrUpdateReseration,
  razorpayCreateOrder,
  razorpayPaymnetVerify,
} from "../../redux/HomePage/actionCreater";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getItem, setItem } from "../../utility/localStorageControl";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { UpOutlined } from "@ant-design/icons";
import footerStlyes from "./footer.module.css";
const { TextArea } = Input;
function ViewCart(props) {
  const [load, setLoad] = useState(false);
  let {
    selectedRoomtype,
    numberOfNights,
    rsSymbol,
    setShowBill,
    showBill,
    customerData,
    currentRegisterData,
    reservationStartDate,
    reservationEndDate,
    setReservationData,
    setSelectedRoomType,
    shopDetails,
    reservationDisable,
    showBill1,
    setShowBill1,
    paymentType,
    currency,
    custom_payment,
    color,
  } = props;

  let customerDetails = customerData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();

  const [totalCalculatedTax, setTotalCalculatedtax] = useState(0);
  const [totalCalculatedPrice, setTotalCalculatedPrice] = useState(0);
  const [totalCalculatedPriceWithoutTax, setTotalCalculatedPriceWihoutTax] =
    useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    setLoad(false);
  }, [customerData]);

  const calCulatedTaxPriceofThisRoomType = (
    totalPrice,
    taxes,
    checkIsInclusive
  ) => {
    let totalTax = 0;
    let inclusiveTax = 0;
    let totalTaxPercent = 0;
    taxes?.map((val) => {
      if (checkIsInclusive != "remove") {
        totalTaxPercent += Number(val.tax_percentage);
      }
    });
    if (checkIsInclusive == true) {
      totalTax = calculateTaxInclusive(totalPrice, totalTaxPercent).taxAmount;
      inclusiveTax = totalTax;
    } else if (checkIsInclusive == false) {
      totalTax = (totalPrice * totalTaxPercent) / 100;
    }
    inclusiveTax = parseFloat(inclusiveTax.toFixed(2));
    totalTax = parseFloat(totalTax.toFixed(2));
    return { totalTax, inclusiveTax };
  };
  function calculateTaxInclusive(totalAmount, taxRate) {
    taxRate = taxRate / 100;
    var originalAmount = totalAmount / (1 + taxRate);
    var taxAmount = totalAmount - originalAmount;
    return {
      originalAmount: originalAmount,
      taxAmount: taxAmount,
    };
  }
  const calculatedPriceOfThisRoomType = (selectRoomType) => {
    if (selectRoomType.room_type.name != "") {
      let totalPrice = 0;
      let totalTaxPrice = 0;
      let totalGuest = 0;

      selectRoomType.rooms?.map((val) => {
        // adult calculated
        let oneRoomCalCulatedPrice = 0;
        if (selectRoomType.room_type.default_adult >= val.adults) {
          let default_adult_rate = selectRoomType.rate_plan.default_adult_rate;
          if (selectRoomType.rate_plan?.adult_rates) {
            let checkadultsPrice = selectRoomType.rate_plan?.adult_rates.find(
              (g, i) => i > 0 && g.numberOfAdult == val.adults
            );
            if (checkadultsPrice) {
              default_adult_rate = checkadultsPrice.rate;
            }
          }
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice + Number(default_adult_rate));
        } else {
          let checkExtraAdult =
            val.adults - selectRoomType.room_type.default_adult;
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice +
              Number(selectRoomType.rate_plan.default_adult_rate));
          let extraPriceOfAdult =
            numberOfNights *
            (checkExtraAdult *
              Number(selectRoomType.rate_plan.extra_adult_rate));
          oneRoomCalCulatedPrice = oneRoomCalCulatedPrice + extraPriceOfAdult;
        }

        // Extra children calculated
        if (val.children > selectRoomType.room_type.default_children) {
          {
            let checkExtraChildren =
              val.children - selectRoomType.room_type.default_children;

            let extraPriceOfChildren =
              numberOfNights *
              (checkExtraChildren *
                Number(selectRoomType.rate_plan.extra_child_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfChildren;
          }
        }

        // Extra infants calculated
        if (val.infants > selectRoomType.room_type.default_infant) {
          {
            let checkExtraInfants =
              val.infants - selectRoomType.room_type.default_infant;

            let extraPriceOfInfants =
              numberOfNights *
              (checkExtraInfants *
                Number(selectRoomType.rate_plan.extra_infant_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfInfants;
          }
        }

        totalPrice = totalPrice + oneRoomCalCulatedPrice;
        totalGuest = val.adults + val.children + val.infants;
      });

      let { totalTax, inclusiveTax } = calCulatedTaxPriceofThisRoomType(
        totalPrice,
        selectRoomType.room_type.tax_group.taxes,
        selectRoomType.room_type.tax_group.taxes_inclusive_in_product_price
      );
      selectRoomType["calculatedPriceWithoutTax"] = totalPrice - inclusiveTax;

      totalTaxPrice = totalTax;
      selectRoomType["calCulatedTaxPrice"] = totalTaxPrice;
      selectRoomType["calCulatedInclusiveTaxPrice"] = inclusiveTax;
      totalPrice = totalPrice + totalTaxPrice - inclusiveTax;
      selectRoomType["calculatedPriceWithTax"] = totalPrice;
      selectRoomType["total_guest"] = totalGuest;
      return selectRoomType;
    }
  };
  useEffect(() => {
    let finalTotalPrice = 0;
    let finalTaxPrice = 0;
    let finalTotalPriceWithoutPrice = 0;

    selectedRoomtype?.map((roomType) => {
      if (roomType.room_type.name != "" && roomType.numberOfRooms > 0) {
        let oneRoomType = calculatedPriceOfThisRoomType(roomType);
        finalTotalPrice = finalTotalPrice + oneRoomType.calculatedPriceWithTax;
        finalTotalPriceWithoutPrice =
          finalTotalPriceWithoutPrice + oneRoomType?.calculatedPriceWithoutTax;
        finalTaxPrice = finalTaxPrice + oneRoomType.calCulatedTaxPrice;
      }
    });
    setTotalCalculatedPriceWihoutTax(finalTotalPriceWithoutPrice);
    setTotalCalculatedPrice(finalTotalPrice);
    setTotalCalculatedtax(finalTaxPrice);
  }, [selectedRoomtype, numberOfNights]);
  const handleSelectRoomType = (selectedRoomtype) => {
    let array = selectedRoomtype.map((val) => {
      if (val?.room_type?.rate_plan_list?.length) {
        let findIndex = val.room_type.rate_plan_list.findIndex(
          (k) => k.rate_id == val.rate_plan.rate_id
        );
        if (findIndex != -1) {
          val.room_type.rate_plan_list[findIndex] = val.rate_plan;
        }
        return val;
      } else {
        return val;
      }
    });
    return array;
  };
  const createReservationDetails = async (advanceAmount) => {
    let formData = {};
    if (currentRegisterData) {
      formData["prefix"] = currentRegisterData.receipt_number_prefix;
      formData["customer"] = customerDetails;
      formData["reservationInDate"] =
        moment(reservationStartDate).format("YYYY-MM-DD");
      formData["reservationOutDate"] =
        moment(reservationEndDate).format("YYYY-MM-DD");
      console.log("reservationStartDate", reservationStartDate);
      let startObject = new Date(reservationStartDate);
      startObject.setHours(12, 30, 0, 0);
      let endObject = new Date(reservationEndDate);
      endObject.setHours(12, 30, 0, 0);
      formData["register_id"] = currentRegisterData?._id;
      formData["register_data"] = {
        _id: currentRegisterData?._id,
        subscription_status: currentRegisterData.subscription_status,
        user_id: currentRegisterData?.user_id,
        main_register_id: currentRegisterData?.register_id,
        shop_name: shopDetails?.shop_name,
        voucher_footer: currentRegisterData?.voucher_footer,
        bill_header: currentRegisterData?.bill_header,
        booking_notification_email:
          currentRegisterData?.booking_notification_email,
      };
      formData["reservationInDateAndTime"] = startObject;
      formData["reservationOutDateAndTime"] = endObject;
      formData["totalNights"] = numberOfNights;
      formData["roomTypes"] = handleSelectRoomType(selectedRoomtype);
      formData["quickReservation"] = false;
      formData["reservationInTime"] = "12:30 pm";
      formData["reservationOutTime"] = "12:30 pm";
      formData["general"] = {
        source: "OTA",
        channel: "Booking Engine",
      };
      // formData["reservationCreatedStaffUserIyd"] = 152;
      // formData["reservationUpdateStaffUserId"] = 45;
      formData["reservationBookTime"] = new Date();
      formData["reservationUpdateTime"] = new Date();
      formData["advanceAmount"] = advanceAmount;
      let paymentObj = {
        discount: 0,
        roomCharges: totalCalculatedPriceWithoutTax,
        roomChargesTax: totalCalculatedTax,
        grandTotal: totalCalculatedPrice,
        currency: rsSymbol,
        extraCharges: 0,
        received: 0,
      };

      formData["payment"] = paymentObj;
      if (customerData?.additional && customerData?.additional != "") {
        formData["notes"] = [
          {
            createAt: new Date(),
            updateAt: new Date(),
            text: customerData?.additional,
            important: true,
            delete: false,
            notesUserName: "Customer",
          },
        ];
      }
    }

    return formData;
  };
  const createChannelPayload = (payload, reservationData) => {
    let roomsStays = [];
    let age10Geuset = 0;
    let age08Geuset = 0;
    payload?.roomTypes?.map((k) => {
      age10Geuset = age10Geuset + k.rooms[0].adults;
      age08Geuset = age08Geuset + k.rooms[0].children;
      if (k.numberOfRooms > 1) {
        for (let i = 0; i < k.numberOfRooms; i++) {
          let calculatedPriceWithoutTax =
            k.calculatedPriceWithoutTax / k.numberOfRooms;
          let calculatedPriceWithTax =
            k?.calculatedPriceWithTax / k.numberOfRooms;
          roomsStays.push({
            RoomTypes: [
              {
                RoomDescription: {
                  Name: k?.room_type?.channel_room_name,
                },
                NumberOfUnits: 1,
                RoomTypeCode: k?.room_type?.channel_room_code,
              },
            ],
            RatePlans: [
              {
                RatePlanCode: k?.rate_plan?.channel_rate_code,
                RatePlanName: k?.rate_plan?.channel_rate_obj?.RatePlanName,
              },
            ],
            GuestCounts: [
              {
                AgeQualifyingCode: "10",
                Count: k?.rooms[0]?.adults?.toString(),
              },
              {
                AgeQualifyingCode: "8",
                Count: k?.rooms[0]?.children?.toString(),
              },
            ],
            TimeSpan: {
              Start: payload?.reservationInDate,
              End: payload?.reservationOutDate,
            },
            RoomRates: createRoomRates(
              payload?.reservationInDate,
              payload?.reservationOutDate,
              calculatedPriceWithoutTax / payload?.totalNights,
              calculatedPriceWithTax / payload?.totalNights
            ),
            Total: {
              AmountBeforeTax: Number(calculatedPriceWithoutTax?.toFixed(2)),
              AmountAfterTax: Number(calculatedPriceWithTax?.toFixed(2)),
              CurrencyCode: currency,
            },
          });
        }
      } else {
        roomsStays.push({
          RoomTypes: [
            {
              RoomDescription: {
                Name: k?.room_type?.channel_room_name,
              },
              NumberOfUnits: k.numberOfRooms,
              RoomTypeCode: k?.room_type?.channel_room_code,
            },
          ],
          RatePlans: [
            {
              RatePlanCode: k?.rate_plan?.channel_rate_code,
              RatePlanName: k?.rate_plan?.channel_rate_obj?.RatePlanName,
            },
          ],
          GuestCounts: [
            {
              AgeQualifyingCode: "10",
              Count: k?.rooms[0]?.adults?.toString(),
            },
            {
              AgeQualifyingCode: "8",
              Count: k?.rooms[0]?.children?.toString(),
            },
          ],
          TimeSpan: {
            Start: payload?.reservationInDate,
            End: payload?.reservationOutDate,
          },
          RoomRates: createRoomRates(
            payload?.reservationInDate,
            payload?.reservationOutDate,
            k?.calculatedPriceWithoutTax / payload?.totalNights,
            k?.calculatedPriceWithTax / payload?.totalNights
          ),
          Total: {
            AmountBeforeTax: Number(k?.calculatedPriceWithoutTax?.toFixed(2)),
            AmountAfterTax: Number(k?.calculatedPriceWithTax?.toFixed(2)),
            CurrencyCode: currency,
          },
        });
      }
    });

    let channelPayload = {
      RoomStays: roomsStays,
      ResGuests: [
        {
          Customer: {
            PersonName: {
              NamePrefix: "Mr",
              GivenName: payload?.customer?.name,
              Surname: "",
            },
            Telephone: {
              PhoneNumber: payload?.customer?.mobile,
            },
            Email: payload?.customer?.email,
            Address: {
              AddressLine: [payload?.customer?.shipping_address],
              CityName: "",
              PostalCode: "",
              StateProv: "",
              CountryName: "",
            },
          },
          PrimaryIndicator: "1",
        },
      ],
      ResGlobalInfo: {
        UniqueID: {
          ID: reservationData?._id,
        },
        BasicPropertyInfo: {
          HotelCode: currentRegisterData?.ota_hotel_code,
          HotelName: shopDetails?.shop_name,
        },
        Source: {
          RequestorID: {
            ID: "Posease",
            Type: "ChannelManager",
          },
          BookingChannel: {
            Type: "OTA",
            CompanyName: "Posease",
            CompanyCode: "",
          },
        },
        CreateDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        ResStatus: "Commit",
        TimeSpan: {
          Start: payload?.reservationInDate,
          End: payload?.reservationOutDate,
        },
        GuestCounts: [
          {
            AgeQualifyingCode: "10",
            Count: age10Geuset,
          },
          {
            AgeQualifyingCode: "8",
            Count: age08Geuset,
          },
        ],
        Total: {
          Taxes: {
            Tax: {
              Amount: payload?.payment?.roomChargesTax,
              CurrencyCode: currency,
            },
          },
          AmountBeforeTax: Number(
            Number(
              payload?.payment?.grandTotal - payload?.payment?.roomChargesTax
            ).toFixed(2)
          ),
          CurrencyCode: currency,
          AmountAfterTax: Number(payload?.payment?.grandTotal.toFixed(2)),
        },
        PaymentTypeInfo: {
          PaymentType: "PayAtHotel",
        },
        SpecialRequests: [""],
      },
    };
    return channelPayload;
  };
  const createRoomRates = (startDate1, endDate1, beforeTax, afterTax) => {
    let startdate = startDate1;
    let endDate = endDate1;
    let RoomRates = [];
    let currentDate = new Date(startdate);
    let end = new Date(endDate);
    while (currentDate < end) {
      RoomRates.push({
        Base: {
          AmountBeforeTax: Number(beforeTax?.toFixed(2)),
          AmountAfterTax: Number(afterTax?.toFixed(2)),
          CurrencyCode: currency,
        },
        EffectiveDate: currentDate.toISOString().split("T")[0], // format as "YYYY-MM-DD"
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return RoomRates;
  };
  const submitReservation = async (advanceAmount) => {
    let payload = await createReservationDetails(advanceAmount);

    if (payload == true) {
      // setReservationDisable(false);
      return true;
    }
    const getReservation = await dispatch(addOrUpdateReseration(payload));
    if (getReservation && getReservation?.data) {
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != ""
      ) {
        let channelPayload = createChannelPayload(payload, getReservation.data);
        if (currentRegisterData?.ota_token) {
          channelPayload["ota_token"] = currentRegisterData?.ota_token;
        }
        if (channelPayload) {
          let channelResponse = await dispatch(
            addBookingInChannel(channelPayload)
          );

          if (channelResponse && channelResponse?.data?.IsSuccess) {
          } else if (channelResponse && channelResponse?.data) {
            message.error({
              content: channelResponse?.data?.Message,
              style: {
                float: "center",
                marginTop: "2vh",
                duration: 5,
              },
            });
          }
        }
      }
      setShowBill(false);
      setShowBill1(false);
      setSelectedRoomType([]);
      setReservationData(getReservation?.data);
      // window.location.reload();
    }
    setLoad(false);
  };
  const handleVerifyPayment = async (payload) => {
    const verificationResult = await dispatch(
      razorpayPaymnetVerify({
        payment_id: payload?.razorpay_payment_id,
        order_id: payload?.razorpay_order_id,
        signature: payload?.razorpay_signature,
      })
    );
    console.log("verificationResult", verificationResult);
    if (verificationResult.success) {
      alert("Payment successfully verified!");
    } else {
      alert("Payment verification failed.");
    }
  };
  const handlePayment = async (body) => {
    if (customerDetails.name == undefined || customerDetails.name == "") {
      message.error("Enter Your Name");
      return true;
    }
    if (customerDetails.email == undefined || customerDetails.email == "") {
      message.error("Enter Your Email");
      return true;
    }
    if (customerDetails.mobile == undefined || customerDetails.mobile == "") {
      message.error("Enter Your Phone");
      return true;
    }
    setLoad(true);
    if (paymentType == "pay_at_hotel") {
      await submitReservation();
      return true;
    } else {
      let advanceAmount = 0;
      if (paymentType == "custom") {
        advanceAmount = (Number(custom_payment) / 100) * totalCalculatedPrice;

        body = {
          amount: Number(advanceAmount).toFixed(2),
          currency: currency,
          register_id: currentRegisterData?._id
        };
      } else if (paymentType == "pay_at_user") {
        advanceAmount = body.amount
        console.log("fasfgafasfafasffsafafafasfafafa", advanceAmount)
        body = {
          amount: Number(advanceAmount).toFixed(2),
          currency: currency,
          register_id: currentRegisterData?._id
        };
      }
      const orderDetails = await dispatch(razorpayCreateOrder(body));
      console.log("fsdfsfsgdfsdsdfafa", orderDetails)
      if (orderDetails.data && !orderDetails.error) {
        const options = {
          key: "rzp_live_1oYjL3ETN9IO5N",
          amount: orderDetails.data.amount,
          currency: currency,
          name: shopDetails?.shop_name,
          description: shopDetails?.shop_name,
          order_id: orderDetails.data.id,
          handler: async function (response) {
            if (response && response?.razorpay_payment_id) {
              await submitReservation(advanceAmount);
            }
          },
          prefill: {
            name: customerData?.name,
            email: customerData?.email,
            contact: customerData?.mobile,
          },
          theme: {
            color: color,
          },
          modal: {
            escape: false,
            ondismiss: function () {
              setLoad(false);
            },
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    }
  };

  return (
    <div>
      <div>
        {windowWidth <= 800 ? (
          <div>
            <div
              className={footerStlyes.pageFooter}
              style={{ "--text-color": color }}
            >
              {1 ? (
                <div className={footerStlyes.viewCartDetailFooter}>
                  <div className={footerStlyes.cartItems} onClick={showModal}>
                    <p>
                      {rsSymbol}
                      {Number(totalCalculatedPrice).toFixed(2)}
                    </p>
                    <UpOutlined className={footerStlyes.cartIcon} />
                  </div>
                  <div className={footerStlyes.cartDetailBtn}>
                    <NavLink to="#">
                      <button
                        onClick={() => {
                          if (selectedRoomtype?.length == 0) {
                            message.error("Please Add Room");
                            return true;
                          }
                          if (reservationDisable) {
                            message.error(
                              " Selected rooms are currently occupied for this category. Please wait for availability or consider offering alternative category."
                            );
                            return true;
                          }
                          if (!showBill) {
                            setShowBill(true);
                            setShowBill1(true);
                          } else {
                            if (load == false) {
                              setLoad(true);
                              // submitReservation();
                              handlePayment({
                                amount: Number(totalCalculatedPrice).toFixed(2),
                              });
                            }
                          }
                        }}
                      >
                        <a href="#" style={{ color: "white" }}>
                          {load
                            ? "Loading..."
                            : showBill
                              ? "Pay Now"
                              : "Proceed"}
                        </a>
                      </button>
                    </NavLink>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Modal
              className={`modalPayNow ${styles.modalPayNow}`}
              title={<b>Price Summary</b>}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <div className={styles.modalPayable}>
                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <h3>Room Total</h3>
                  </div>
                  <div className="d-flex flex-column">
                    <h3>
                      {rsSymbol}
                      {Number(totalCalculatedPriceWithoutTax).toFixed(2)}
                    </h3>
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <h3>Tax</h3>
                  </div>
                  <div className="d-flex flex-column">
                    <h3>
                      {rsSymbol}
                      {Number(totalCalculatedTax).toFixed(2)}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-between ${styles.totalPay}`}
              >
                <h3>Total payable</h3>
                <h3>
                  {rsSymbol}
                  {Number(totalCalculatedPrice).toFixed(2)}
                </h3>
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
      {windowWidth > 800 || showBill ? (
        <div
          style={{ "--text-color": color }}
          className={styles.cardPayDetails}
        >
          <h2>Booking Details</h2>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <h3>Check-in</h3>
              <p>{moment(reservationStartDate).format("ddd, DD MMM YYYY")}</p>
            </div>
            <div className={styles.oneN}>{numberOfNights}N</div>
            <div className="d-flex flex-column">
              <h3 style={{ textAlign: " right" }}>Check-out</h3>
              <p style={{ textAlign: " right" }}>
                {moment(reservationEndDate).format("ddd, DD MMM YYYY")}
              </p>
            </div>
          </div>
          <hr />
          <div>
            {selectedRoomtype?.map((val) => {
              return (
                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <h3>
                      {val.numberOfRooms} X {val?.room_type?.name}{" "}
                      {val?.rate_plan?.rate_name}
                    </h3>

                    {val.rooms.map((k, i) => {
                      return (
                        <p
                          style={{
                            marginTop: i == 0 ? -5 : 0,
                            marginBottom: val.rooms.length == i + 1 ? 10 : 1,
                          }}
                        >
                          {k.adults} Adult - {k.children} Children - {k.infants}{" "}
                          Infant
                        </p>
                      );
                    })}
                  </div>
                  <div className="d-flex flex-column">
                    <h3>
                      {rsSymbol}
                      {Number(val.calculatedPriceWithoutTax).toFixed(2)}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
          {showBill && (
            <p
              style={{
                color: "rgb(0 127 255)",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 600,
                marginBottom: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowBill(false);
                setShowBill1(false);
              }}
            >
              Change your selection
            </p>
          )}

          <label>Price Summary</label>
          <hr />
          <div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex flex-column">
                <h3>Room Total</h3>
              </div>
              <div className="d-flex flex-column">
                <h3>
                  {rsSymbol}
                  {Number(totalCalculatedPriceWithoutTax).toFixed(2)}
                </h3>
              </div>
            </div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex flex-column">
                <h3>Tax</h3>
              </div>
              <div className="d-flex flex-column">
                <h3>
                  {rsSymbol}
                  {Number(totalCalculatedTax).toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
          <div className={`d-flex justify-content-between ${styles.totalPay}`}>
            <h3>Total payable</h3>
            <h3>
              {rsSymbol}
              {Number(totalCalculatedPrice).toFixed(2)}
            </h3>
          </div>
          {windowWidth > 800 && (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (selectedRoomtype?.length == 0) {
                  message.error("Please Add Room");
                  return true;
                }
                if (reservationDisable) {
                  message.error(
                    " Selected rooms are currently occupied for this category. Please wait for availability or consider offering alternative category."
                  );
                  return true;
                }
                if (!showBill) {
                  setShowBill(true);
                  setShowBill1(true);
                } else {
                  if (load == false) {
                    // submitReservation();
                    handlePayment({
                      amount: Number(totalCalculatedPrice).toFixed(2),
                    });
                  }
                }
              }}
            >
              <a href="#">
                {load ? "Loading..." : showBill ? "Pay Now" : "Proceed"}
              </a>
            </div>
          )}

          <div>
            <div
              className={`d-flex flex-column justify-content-between ${styles.guarantedMsg}`}
            >
              <h5>Lowest price guaranteed</h5>
              <p>
                You will get the lowest room price on our platform compared to
                others. We guarantee it.
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ViewCart;
