import { DatePicker, Skeleton, Space, Form, Row, Col } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import "../../css/custom.css";
import BookingDetails from "./BookingDetails";
import Customer from "./customer";
import Footer from "./footer";
import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import Confirmed from "./Confirmed";
import {
  getHotelDetails,
  menuItemsListData,
  getAllRateDateWise,
} from "../../redux/HomePage/actionCreater";

import { useParams, useLocation, useNavigate } from "react-router-dom";
function Header({ state }) {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  let navigate = useNavigate();
  const [hotelData, setHotelData] = useState();
  const [currentRegisterData, setCurrentRegisterData] = useState();
  const params = useParams();
  const [roomType, setRoomtype] = useState([]);
  const [rsSymbol, setRsSymbol] = useState("₹");
  const [shopDetails, setShopDetails] = useState();
  const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});
  const [reservationData, setReservationData] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const [showBill1, setShowBill1] = useState(false);
  const [color, setColor] = useState("#2d2a21");
  const tableData = async () => {
    if (params.table_id != "home") {
      let response1 = await dispatch(getHotelDetails(params.table_id));
      if (response1 && response1.data) {
        setColor(response1.data?.bg_color);
        setHotelData(response1.data);
        setLoad(false);
      }
    } else {
      setLoad(false);
    }
  };
  const [roomFetchLeader, setRoomFetchLoader] = useState(true);
  const [stayForm] = Form.useForm();
  const tableData1 = async () => {
    if (params.table_id != "home") {
      setRoomFetchLoader(true);
      let response = await dispatch(menuItemsListData(params.table_id));
      if (response && response.data) {
        let { shopDetails, roomTypeList, registerDetails } = response.data;
        setRoomtype(roomTypeList);
        setRsSymbol(
          shopDetails?.rs_symbol
            ? /\(([^)]+)\)/.exec(shopDetails.rs_symbol)?.length > 0
              ? /\(([^)]+)\)/.exec(shopDetails.rs_symbol)[1]
              : shopDetails.rs_symbol
            : "₹"
        );
        setShopDetails(shopDetails);
        setCurrentRegisterData(registerDetails);

        setRoomFetchLoader(false);
      }
    } else {
      setRoomFetchLoader(false);
    }
  };
  useEffect(() => {
    if (!reservationData) {
      tableData();
      tableData1();
    }
  }, [reservationData]);
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  const [reservationStartDate, setReservationStartDate] = useState(
    startDate ? startDate : moment().format("ddd DD MMM YYYY")
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    endDate ? endDate : moment().add(1, "day").format("ddd DD MMM YYYY")
  );
  function disabledEndDate(current) {
    return current && current <= moment(reservationStartDate);
  }

  const handleDateChange = (dates) => {
    setReservationStartDate(moment(dates[0]).format("ddd DD MMM YYYY"));
    setReservationEndDate(moment(dates[1]).format("ddd DD MMM YYYY"));
  };
  const [rateLoader, setRateLoader] = useState(false);
  const fetchDateWiseRate = async () => {
    setRateLoader(true);
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(params.table_id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      setRateDatewiseRateObj(obj);
      setRateLoader(false);
      return obj;
    }
  };
  useEffect(() => {
    if (!reservationData) {
      fetchDateWiseRate();
    }
  }, [reservationStartDate, reservationData, reservationEndDate]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [reservationData]);
  const navigateHotelPage = () => {
    navigate(`/hotel/${params.table_id}`);
  };
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  if (load || rateLoader || roomFetchLeader) {
    return (
      <div className={styles.SkeletonContainer}>
        <Skeleton title width={150} active={true} />
        <br />
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <Space>
          <Skeleton.Button active={true} size="large" />
          <Skeleton.Input active={true} />
        </Space>
      </div>
    );
  }

  return (
    <div>
      <header className={styles.mainHeader} style={{ "--text-color": color }}>
        <div className="container">
          <div>
            <h1 style={{ cursor: "pointer" }} onClick={navigateHotelPage}>
              {hotelData?.hotel_name}
            </h1>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-2  d-flex gap-3">
                <i class="fa-solid fa-hotel"></i>
                <p>Hotel Details</p>
              </div>
              <div className=" col-12 col-md-6 d-flex gap-3">
                <i class="fa-solid fa-location-dot"></i>
                <p>{hotelData?.hotel_address}</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2 d-flex gap-3">
                <i class="fa-solid fa-phone-volume"></i>
                <a href="#">{hotelData?.hotel_mobile}</a>
              </div>
              <div className="col-12 col-md-6 col-lg-2 d-flex gap-3">
                <i class="fa-regular fa-star"></i>
                <p>4.4 (1285 reviews)</p>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!reservationData && showBill1 == false && (
        <section className={styles.checkSection}>
          <div className="container">
            <div className="d-flex align-items-center gap-3">
              <h2>Check In & Check Out</h2>
              <Space direction="vertical" className={styles.spaceHeaderPicker}>
                <Form form={stayForm} name="stayDetails">
                  <Row>
                    <Col
                      md={12}
                      sm={12}
                      lg={12}
                      xxl={12}
                      xs={24}
                      className="gutter-row"
                    >
                      <Form.Item
                        name="stay_start_date"
                        className={styles.startEndDate}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          defaultValue={moment(
                            reservationStartDate,
                            "ddd DD MMM YYYY"
                          )}
                          size="large"
                          disabledDate={disabledDate}
                          onChange={(date, string) => {
                            let nights = moment(reservationEndDate).diff(
                              moment(string),
                              "days"
                            );
                            setReservationStartDate(string);
                            let outDate = reservationEndDate;
                            if (nights <= 0) {
                              outDate = moment(string)
                                .add(1, "days")
                                .format("ddd DD MMM YYYY");
                              stayForm.setFieldsValue({
                                stay_end_date: moment(
                                  outDate,
                                  "ddd DD MMM YYYY"
                                ),
                              });
                              setReservationEndDate(outDate);
                            }
                          }}
                          format="ddd DD MMM YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                      <Form.Item
                        name="stay_end_date"
                        className={styles.startEndDate}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          defaultValue={moment(
                            reservationEndDate,
                            "ddd DD MMM YYYY"
                          )}
                          size="large"
                          disabledDate={disabledEndDate}
                          onChange={(date, string) => {
                            setReservationEndDate(string);
                          }}
                          format="ddd DD MMM YYYY"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Space>
            </div>
          </div>
        </section>
      )
      }

      {
        reservationData ? (
          <Confirmed
            setReservationData={setReservationData}
            reservationData={reservationData}
            currentRegisterData={currentRegisterData}
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
            setReservationStartDate={setReservationStartDate}
            setReservationEndDate={setReservationEndDate}
            shopDetails={shopDetails}
            color={color}
          />
        ) : (
          <BookingDetails
            currentRegisterData={currentRegisterData}
            roomType={roomType}
            rsSymbol={rsSymbol}
            shopDetails={shopDetails}
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
            rateDateWiseObj={rateDateWiseObj}
            cancelPolicy={
              hotelData?.cancel_policy ? hotelData?.cancel_policy : ""
            }
            setReservationData={setReservationData}
            reservationData={reservationData}
            currency={hotelData?.currency}
            custom_payment={hotelData?.custom_payment}
            setShowBill1={setShowBill1}
            showBill1={showBill1}
            paymentType={hotelData?.payment_type}
            price_adjustment={hotelData?.price_adjustment}
            percentage_adjustment={hotelData?.percentage_adjustment}
            color={color}
          />
        )
      }
      {windowWidth > 800 && <Footer hotelData={hotelData} color={color} />}
    </div >
  );
}

export default Header;
