import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import Private from "./routes/Private";
import "antd/dist/antd.css";
function App() {
  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <Suspense>
        <Private />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
