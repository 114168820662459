import React, { useState } from "react";
import "../../css/custom.css";
import styles from "./Confirmed.module.css";
import moment from "moment";
import { Alert, Skeleton, Space } from "antd";
import rightImg from "../../Images/rightImg.png";
import ConformationPrint from "./conformationPrint";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
function Confirmed({
  currentRegisterData,
  reservationStartDate,
  reservationEndDate,
  setReservationStartDate,
  setReservationEndDate,
  reservationData,
  shopDetails,
  setReservationData,
  color,
}) {
  const PrintReceiptFunction = (check, receiptsDetails) => {
    let numberOfNights = moment(reservationEndDate).diff(
      moment(reservationStartDate),
      "days"
    );
    let rooms = [];
    let checkAssign = false;
    let roomName = [{ label: `All`, value: "All" }];
    let pendingAmount = 0;
    let checkOutRoom = 0;
    let checkOutCount = 0;
    let withoutCancelRoom = 0;
    let checkAll = false;
    let totalWithoutTax = 0;
    let totalTax = 0;

    reservationData.roomInfo.map((val, index) => {
      if (!val.isCancelled) {
        pendingAmount = pendingAmount + Number(val.calculatedPriceWithTax);
        withoutCancelRoom = withoutCancelRoom + 1;
        totalWithoutTax =
          totalWithoutTax + Number(val.calculatedPriceWithoutTax);
        totalTax = totalTax + Number(val.calCulatedTaxPrice);
      }
      if (val.isCheckedIn && !val.isCancelled) {
        checkOutRoom = checkOutRoom + 1;
      }
      if (val.isCheckedOut && !val.isCancelled) {
        checkOutCount = checkOutCount + 1;
      }
      val.avialbleRoomList = [];

      if (
        moment().startOf("day").set({ hour: 12, minute: 30 }) <
        moment(val?.reservationInDateAndTime)
      ) {
        val["checkUpcoming"] = true;
      } else {
        val["checkUpcoming"] = false;
      }

      rooms.push({
        ...val,
        cancel: val.isCancelled ? true : false,
        type: "room",
        addTime: val.checkOutDateAndTime
          ? val.checkOutDateAndTime
          : val.checkInDateAndTime
          ? val.checkInDateAndTime
          : val.assignDateAndTime
          ? val.assignDateAndTime
          : val.reservationBookTime,
      });
      roomName.push({
        label: `${val.room_type.name} ${val.room_name}`,
        value: val._id,
      });
      if (val.isAssignIn || val.isCancelled) {
        checkAssign = true;
      }
      val?.addtionalItem?.map((r) => {
        rooms.push({
          type: "addtional-item",
          item: r.itemName,
          calculatedPriceWithoutTax: Number(r.amount.total),
          paid_room: r.roomName,
          room_type: val.room_type,
          customerInfo: val.customerInfo,
          roomInfoId: val._id,
          itemId: r.itemId,
          amount: r.amount,
          updateId: r._id,
          poseaseBill: r.type == "posease-restaurant" ? true : false,
          cancel: r.cancel ? true : false,
          addTime: r.addTime,
          notes: r.notes,
        });
        if (!r.cancel) {
          pendingAmount = pendingAmount + Number(r.amount.total);
        }
      });

      val?.transationList?.map((r) => {
        if (r) {
          if (r.type != "pending") {
            rooms.push({
              type: "payment",
              item: r.type,
              calculatedPriceWithoutTax: r.amount,
              paid_room: r.roomName,
              room_type: val.room_type,
              customerInfo: val.customerInfo,
              transId: r._id,
              cancel: r.cancel ? true : false,
              cancel_notes: r.cancel_notes,
              addTime: r.paidTime,
              notes: r.notes,
            });
          }

          if (!r.cancel && r.type != "pending") {
            if (r.folio_type == "All") {
              checkAll = true;
            }
            pendingAmount = pendingAmount - Number(r.amount);
          }
        }
      });
    });
    const sortByDate = (a, b) => {
      return new Date(b.addTime) - new Date(a.addTime);
    };
    // Create a container for the generated HTML
    const htmlContent = ReactDOMServer.renderToStaticMarkup(
      <ConformationPrint
        reservationData={reservationData}
        currentRegisterData={currentRegisterData}
        pendingPrice={pendingAmount}
        roomList={rooms.sort(sortByDate)}
        numberOfNights={numberOfNights}
        shopDetails={shopDetails}
      />
    );
    const element = document.createElement("div");
    element.innerHTML = htmlContent;
    console.log("htmlContent", htmlContent);
    // Convert HTML content to PDF using html2pdf
    html2pdf()
      .from(element)
      .set({
        margin: 0.7,
        filename: "reservation-confirmation.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save();
  };

  return (
    <>
      <section
        style={{ "--text-color": color }}
        className={styles.confirmedSection}
      >
        <div className="container d-flex justify-content-center">
          <div className={styles.confirmedCard}>
            <img src={rightImg} alt="" />
            <h1>Booking Confirmed !</h1>
            <p>
              Thank you for choosing to stay with us. your reservation has been
              successfully completed.
            </p>
            <div
              className={styles.cartDetailBtn}
              onClick={() => PrintReceiptFunction()}
            >
              Download Voucher
            </div>
            <div
              className={styles.cartDetailReady}
              onClick={() => {
                setReservationStartDate(moment().format("ddd DD MMM YYYY"));
                setReservationEndDate(
                  moment().add(1, "day").format("ddd DD MMM YYYY")
                );
                setReservationData();
              }}
            >
              <p>Ready to Plan another stay ?</p>
              <a href="#">{`Make a New Booking --->`}</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Confirmed;
