import React, { useState, useEffect, useRef } from "react";
import styles from "./home.module.css";
import "../../css/custom.css";
import {
  Input,
  Row,
  Col,
  Divider,
  Popover,
  Tag,
  Button,
  Badge,
  Checkbox,
  Drawer,
  Form,
  Dropdown,
  Space,
  Skeleton,
  Modal,
} from "antd";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CaretRightOutlined,
  SearchOutlined,
  MinusOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
  FilterFilled,
  CloseOutlined,
  FilterTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { menuItemsListData } from "../../redux/HomePage/actionCreater";
import dish1 from "../../Images/Home/dish1.png";
import menuIcon from "../../Images/Home/menuBook.svg";
import dish4 from "../../Images/Home/dish4.jpg";
import ProductDetail from "./ProductDetail";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
import ViewCart from "./ViewCart";
const { Search } = Input;

function HotelList({ state }) {
  let localCartInfo = {};
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [currentRegisterData, setCurrentRegisterData] = useState();
  const params = useParams();
  useEffect(() => {
    tableData();
  }, []);
  const [roomType, setRoomtype] = useState([]);
  const [rsSymbol, setRsSymbol] = useState("₹");
  const [shopDetails, setShopDetails] = useState();
  const tableData = async () => {
    setLoad(false);
  };

  if (load) {
    return (
      <div className={styles.SkeletonContainer}>
        <Skeleton title width={150} active={true} />
        <br />
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <br />
        <div className={styles.cardSkeleton}>
          <Skeleton paragraph={{ rows: 3 }} active={true} />
          <Skeleton.Image active={true} />
        </div>
        <Space>
          <Skeleton.Button active={true} size="large" />
          <Skeleton.Input active={true} />
        </Space>
      </div>
    );
  }

  return (
    <div>
      <h1>Hotel List</h1>
    </div>
  );
}

export default HotelList;
